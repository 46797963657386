import React from 'react';

import './style.scss';
import Button from "../../Button";

import {history} from '../../../redux/store';

interface ICancelOrderPopup {
    isSuccessCancelOrder: boolean,
    isErrorCancelOrder: boolean,
    isErrorCancelOrderId: boolean,
    handleCloseCancelOrderPopup: () => void
}

const CancelOrderPopup: React.FC<ICancelOrderPopup> = ({
        handleCloseCancelOrderPopup,
        isSuccessCancelOrder,
        isErrorCancelOrder,
        isErrorCancelOrderId
    }) => {

    const handleClick = () => {
        handleCloseCancelOrderPopup()
        history.push('/');
    }

    return (
        <div className='cancelOrder'>
            <div className="cancelOrder_inner">
                <p className="cancelOrder_inner_text">
                    {
                        isErrorCancelOrder ?
                            'На жаль, час скасування замовлення на прокат закінчився' :
                            isSuccessCancelOrder ?
                                'Ваше замовлення на прокат успішно скасовано!'
                                : isErrorCancelOrderId ?
                                    'Номер замовлення не вказано'
                                    : ''
                    }
                </p>
                <Button textBtn='Повернутися на головну' onClick={handleClick} />
            </div>
        </div>
    );
};

export default CancelOrderPopup;
