import {LangActions, LangType} from "../actions/langAction";

const initialState = {
    lang: ""
};

export const langReducer = (state = initialState, action: LangType) => {
    switch (action.type) {
        case LangActions.SET_LANG:
            return {
                ...state,
                lang: action.payload
            }
        default:
            return state
    }
}