// CORE
import React, {useEffect, useState} from 'react';
import {loadAdminItemFromStart} from "../../redux/actions";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import imageCompression from 'browser-image-compression'
import {history} from "../../redux/store";
import axios from "axios";
import {getNotification} from "../../utils/utils";
// STYLES
import './style.scss';

const data = {
    category: ['лыжи', 'ботинки', 'аксессуары'],
    ukrCategory: ['лижі', 'черевики', 'аксесуари'],
    type: ['Стандарт', 'Премиум'],
    ukrType: ['Стандарт', 'Преміум'],
    gender: ['Мужской', 'Женский', 'Детский'],
    ukrGender: ['Чоловічий', 'Жіночий', 'Дитячий']
}

interface IAdminOrderPage {
    orderId?: string
}

const AdminItemPage: React.FC<IAdminOrderPage> = () => {
    const dispatch = useDispatch();

    const isAddOrEditing = window.location.pathname.split('/')[3] === 'create';

    // REDUX

    const singleAdminItemLoad = useSelector((state: RootStateOrAny) => state.adminData.singleAdminItemLoad);

    // STATE

    const [fields, setFields] = useState<any>({
        id: 0,
        name: '',
        articul: '',
        price: 0,
        zalog: 0
    })

    const handleChangeFields = (e: any) => setFields({...fields, [e.name]: e.value})

    const [description, setDescription] = useState('');
    const handleChangeDescription = (e: any) => setDescription(e.value);

    const [ukrDescription, setUkrDescription] = useState('');
    const handleChangeUkrDescription = (e: any) => setUkrDescription(e.value);

    const [category, setCategory] = useState('лыжи');
    const [isOpenCatDD, setOpenCatDD] = useState(false);
    const handleChooseCategory = (e: string) => {
        setCategory(e);
        setOpenCatDD(false)
    }
    const handleOpenCatDD = () => setOpenCatDD(!isOpenCatDD);

    const [ukrCategory, setUkrCategory] = useState('лижі');
    const [isOpenUkrCatDD, setOpenUkrCatDD] = useState(false);
    const handleChooseUkrCategory = (e: string) => {
        setUkrCategory(e);
        setOpenUkrCatDD(false)
    }
    const handleOpenUkrCatDD = () => setOpenUkrCatDD(!isOpenUkrCatDD);

    const [type, setType] = useState('Стандарт');
    const [isOpenTypeDD, setOpenTypeDD] = useState(false);
    const handleChooseType = (e: string) => {
        setType(e);
        setOpenTypeDD(false)
    }
    const handleOpenTypeDD = () => setOpenTypeDD(!isOpenTypeDD);

    const [ukrType, setUkrType] = useState('Стандарт');
    const [isOpenUkrTypeDD, setOpenUkrTypeDD] = useState(false);
    const handleChooseUkrType = (e: string) => {
        setUkrType(e);
        setOpenUkrTypeDD(false)
    }
    const handleOpenUkrTypeDD = () => setOpenUkrTypeDD(!isOpenUkrTypeDD);

    const [gender, setGender] = useState('Мужской');
    const [isOpenGenderDD, setOpenGenderDD] = useState(false);
    const handleChooseGender = (e: string) => {
        setGender(e);
        setOpenGenderDD(false)
    }
    const handleOpenGenderDD = () => setOpenGenderDD(!isOpenGenderDD);

    const [ukrGender, setUkrGender] = useState('Чоловічий');
    const [isOpenUkrGenderDD, setOpenUkrGenderDD] = useState(false);
    const handleChooseUkrGender = (e: string) => {
        setUkrGender(e);
        setOpenUkrGenderDD(false)
    }
    const handleOpenUkrGenderDD = () => setOpenUkrGenderDD(!isOpenUkrGenderDD);

    const [specifications, setSpecifications] = useState<{ id: number, name: string, text: string }[]>([])
    const handleChangeSpecification = (e: any, id: number) => setSpecifications(specifications.map(todo => todo.id === id ? {...todo, [e.name]: e.value} : todo));
    const handleAddSpecification = () => {
        setSpecifications([...specifications, {
            id: specifications.length,
            name: '',
            text: '',
        }])
    }

    const handleDeleteSpecifications = (id: number) => setSpecifications(specifications.filter(e => e.id !== id))

    const [ukrSpecifications, setUkrSpecifications] = useState<{ id: number, name: string, text: string }[]>([])
    const handleChangeUkrSpecification = (e: any, id: number) => setUkrSpecifications(ukrSpecifications.map(todo => todo.id === id ? {...todo, [e.name]: e.value} : todo));
    const handleAddUkrSpecification = () => {
        setUkrSpecifications([...ukrSpecifications, {
            id: ukrSpecifications.length,
            name: '',
            text: '',
        }])
    }

    const handleDeleteUkrSpecifications = (id: number) => setUkrSpecifications(ukrSpecifications.filter(e => e.id !== id))

    const [additionalInfo, setAdditionalInfo] = useState<{ id: number, name: string, text: string }[]>([])
    const handleChangeAdditionalInfo = (e: any, id: number) => setAdditionalInfo(additionalInfo.map(todo => todo.id === id ? {...todo, [e.name]: e.value} : todo));
    const handleAddAdditionalInfo = () => {
        setAdditionalInfo([...additionalInfo, {
            id: additionalInfo.length,
            name: '',
            text: '',
        }])
    }

    const handleDeleteAdditionalInfo = (id: number) => setAdditionalInfo(additionalInfo.filter(e => e.id !== id))

    const [ukrAdditionalInfo, setUkrAdditionalInfo] = useState<{ id: number, name: string, text: string }[]>([])
    const handleChangeUkrAdditionalInfo = (e: any, id: number) => setUkrAdditionalInfo(ukrAdditionalInfo.map(todo => todo.id === id ? {...todo, [e.name]: e.value} : todo));
    const handleAddUkrAdditionalInfo = () => {
        setUkrAdditionalInfo([...ukrAdditionalInfo, {
            id: ukrAdditionalInfo.length,
            name: '',
            text: '',
        }])
    }

    const handleDeleteUkrAdditionalInfo = (id: number) => setUkrAdditionalInfo(ukrAdditionalInfo.filter(e => e.id !== id))

    const [vars, setVars] = useState({
        name: 'Размер',
        text: '0',
        quantity: 0
    })
    const handleChangeVars = (e: any) => setVars({...vars, [e.name]: e.value})

    const [downloadFile, setDownloadFile] = useState<{ imagePreview: any, name: any, file?: any, files?: any }[]>([]);

    const handleImageChange = async (e: any) => {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
            initialQuality: 0.8
        }

        try {
            const compressedFile = await imageCompression(file, options);

            reader.onloadend = (e: any) => {
                setDownloadFile([{
                    file: compressedFile,
                    files: e.target.result,
                    imagePreview: reader.result,
                    name: file.name
                }]);
            };
        } catch (error) {
            console.log(error);
        }

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleDeleteItem =  async (id: number) => {
        const res = await axios.delete(`/api/v1/item/${id}`);

        if(res) {
            getNotification('success', 'Товар был успешно удален')
            history.push('/admin')
        } else {
            getNotification('success', 'Что то пошло не так')
        }
    };

    const handleValidationCreating = () => {
        let isValidated = false;

        const handleCheckEmptyField = (field: any) => field.toString().trim().length > 0;

        if(
            handleCheckEmptyField(fields.name) &&
            handleCheckEmptyField(category) &&
            handleCheckEmptyField(fields.zalog) &&
            handleCheckEmptyField(vars.quantity) &&
            handleCheckEmptyField(fields.articul) &&
            handleCheckEmptyField(description) &&
            handleCheckEmptyField(type) &&
            handleCheckEmptyField(gender) &&
            handleCheckEmptyField(vars.text) &&
            handleCheckEmptyField(fields.price) &&
            handleCheckEmptyField(ukrDescription) &&
            handleCheckEmptyField(ukrCategory) &&
            handleCheckEmptyField(ukrType) &&
            handleCheckEmptyField(ukrGender) &&
            downloadFile.length !== 0
        ) {
            isValidated = true;
        }

        return isValidated;
    }

    const handleEditQuery = async () => {
        if(handleValidationCreating()) {
            const checkField = (text: string) => {
                return text.trim().length > 0 && !text.trim().includes('undefined');
            };

            let formdata = new FormData();

            !isAddOrEditing && formdata.append("id", singleAdminItemLoad.id.id)

            formdata.append("title", fields.name);
            formdata.append("type", category);
            formdata.append("zalog", `${fields.zalog}`);
            formdata.append("quantity", `${vars.quantity}`);
            specifications.length !== 0 ?
                formdata.append("specifications", specifications.map(e => `${checkField(e.name) ? e.name : ''}=${checkField(e.text) ? e.text : ''}`).join('&')) :
                formdata.append("specifications", '');
            formdata.append("articul", fields.articul);
            additionalInfo.length !== 0 ?
                formdata.append("additionalInfo", additionalInfo.map(e => `${checkField(e.name) ? e.name : ''}=${checkField(e.text) ? e.text : ''}`).join('&')) :
                formdata.append("additionalInfo", '')
            formdata.append("description", description);
            formdata.append("category", type);
            formdata.append("season", "2020/2021");
            formdata.append("gender", gender);
            // @ts-ignore
            formdata.append("size", parseFloat(vars.text));
            //@ts-ignore
            formdata.append("price", `${parseFloat(fields.price)}`);

            formdata.append("descriptionUkr", ukrDescription);
            ukrAdditionalInfo.length !== 0 ?
                formdata.append("additionalInfoUkr", ukrAdditionalInfo.map(e => `${checkField(e.name) ? e.name : ''}=${checkField(e.text) ? e.text : ''}`).join('&')) :
                formdata.append("additionalInfoUkr", '')
            formdata.append('typeUkr', ukrCategory);
            formdata.append('categoryUkr', ukrType);
            formdata.append("genderUkr", ukrGender);
            ukrSpecifications.length !== 0 ?
                formdata.append("specificationsUkr", ukrSpecifications.map(e => `${checkField(e.name) ? e.name : ''}=${checkField(e.text) ? e.text : ''}`).join('&')) :
                formdata.append("specificationsUkr", '')
            downloadFile[0]?.file && formdata.append("file", downloadFile[0].file);

            const res = await axios({
                method: "post",
                url: "/api/v1/item",
                data: formdata,
                headers: { "Content-Type": "multipart/form-data" },
            });

            if(res.status === 201) {
                getNotification('success', `Товар успешно ${isAddOrEditing ? 'добавлен' : "изменен"}`)
                history.push('/admin')
            } else {
                getNotification('warning', 'Что то пошло не так')
            }
        } else {
            getNotification('warning', 'Проверьте заполнение полей')
        }
    };

    // LIFECYCLE

    useEffect(() => {
        if(!isAddOrEditing) {
            const itemId = window.location.pathname.split('/')[3];
            dispatch(loadAdminItemFromStart({adminId: itemId, accessToken: ''}));
    }}, [])

    useEffect(() => {
        if (!isAddOrEditing) {
            if (Object.keys(singleAdminItemLoad).length !== 0) {
                setFields({
                    ...fields,
                    id: singleAdminItemLoad.id,
                    name: singleAdminItemLoad.title !== null ? singleAdminItemLoad.title : '',
                    articul: singleAdminItemLoad.articul,
                    price: singleAdminItemLoad.price,
                    zalog: singleAdminItemLoad.zalog
                })

                setDownloadFile([{
                    imagePreview:  singleAdminItemLoad ? `data:${singleAdminItemLoad.photo.contentType};base64,${singleAdminItemLoad.photo.bytes}`: '',
                    name: singleAdminItemLoad ?  singleAdminItemLoad.name : ''
                }])

                setVars({name: 'Размер', text: singleAdminItemLoad.size, quantity: singleAdminItemLoad.quantity})

                setDescription(singleAdminItemLoad.description !== null ? singleAdminItemLoad.description : '')
                setCategory(singleAdminItemLoad.type !== null ? singleAdminItemLoad.type : '');
                setType(singleAdminItemLoad.category !== null ? singleAdminItemLoad.category : '');
                setGender(singleAdminItemLoad.gender !== null ? singleAdminItemLoad.gender : '');

                setSpecifications(singleAdminItemLoad.specifications ? singleAdminItemLoad.specifications.split('&').map((e: any, idx: number) => {
                    const part = e.split('=');
                    return {id: idx, name: part[0], text: part[1]}
                }) : [])
                setAdditionalInfo(singleAdminItemLoad.additionalInfo ? singleAdminItemLoad.additionalInfo.split('&').map((e: any, idx: number) => {
                    const part = e.split('=');
                    return {id: idx, name: part[0], text: part[1]}
                }): [])

                setUkrDescription(singleAdminItemLoad.descriptionUkr !== null ? singleAdminItemLoad.descriptionUkr : '')
                setUkrCategory(singleAdminItemLoad.typeUkr !== null ? singleAdminItemLoad.typeUkr : '');
                setUkrType(singleAdminItemLoad.categoryUkr !== null ? singleAdminItemLoad.categoryUkr : '');
                setUkrGender(singleAdminItemLoad.genderUkr !== null ? singleAdminItemLoad.genderUkr : '');

                setUkrSpecifications(singleAdminItemLoad.specificationsUkr ? singleAdminItemLoad.specificationsUkr.split('&').map((e: any, idx: number) => {
                    const part = e.split('=');
                    return {id: idx, name: part[0], text: part[1]}
                }) : [])
                setUkrAdditionalInfo(singleAdminItemLoad.additionalInfoUkr ? singleAdminItemLoad.additionalInfoUkr.split('&').map((e: any, idx: number) => {
                    const part = e.split('=');
                    return {id: idx, name: part[0], text: part[1]}
                }): [])
            }
        }
    }, [singleAdminItemLoad])

    return (
        <div className='adminItem'>
            {singleAdminItemLoad && <div className="adminItem_inner container">
                <div className="adminItem_inner_header">
                    <h2 className="adminItem_inner_title">Добавление товара</h2>
                    <div className="adminItem_inner_header_btns">
                        <div className="adminItem_inner_header_btns_edit">
                            <img src="/images/editAdminDD.svg" alt="editAdmin"/>
                            <button
                                className="adminItem_inner_header_btns_edit_btn"
                                onClick={handleEditQuery}
                            >
                                {window.location.pathname.split('/')[3] !== 'create' ? 'Редактировать' : "Добавить"}
                            </button>
                        </div>
                        <div className="adminItem_inner_header_btns_delete">
                            <img src="/images/trashAdmin.svg" alt="trashAdmin"/>
                            <button
                                className="adminItem_inner_header_btns_delete_btn"
                                onClick={() => handleDeleteItem(fields.id.id)}
                            >
                                Удалить товар
                            </button>
                        </div>
                    </div>
                </div>
                <div className="adminItem_inner_main">
                    <div className="adminItem_inner_main_left">
                        <h2 className="adminItem_inner_main_left_title">Детали товара</h2>
                        <div className="adminItem_inner_main_left_nameWrapper">
                            <h3 className="adminItem_inner_main_left_nameWrapper_title">Название товара<span className='required-field'>&#9432;</span></h3>
                            <input
                                type="text"
                                className="adminItem_inner_main_left_nameWrapper_input required_input"
                                onChange={e => handleChangeFields(e.target)}
                                name='name'
                                value={fields.name}
                            />
                        </div>
                        <div className="adminItem_inner_main_left_articulWrapper">
                            <h3 className="adminItem_inner_main_left_articulWrapper_title">Артикул<span className='required-field'>&#9432;</span></h3>
                            <input
                                type="text"
                                className="adminItem_inner_main_left_articulWrapper_input"
                                onChange={e => handleChangeFields(e.target)}
                                name='articul'
                                value={fields.articul}
                            />
                        </div>
                        <div className="adminItem_inner_main_left_priceZalogWrapper">
                            <div className="adminItem_inner_main_left_priceZalogWrapper_price">
                                <h3 className="adminItem_inner_main_left_priceZalogWrapper_price_title">Цена за сутки</h3>
                                <input
                                    type="text"
                                    className="adminItem_inner_main_left_priceZalogWrapper_price_input"
                                    onChange={e => handleChangeFields(e.target)}
                                    name='price'
                                    value={fields.price}
                                />
                            </div>
                            <div className="adminItem_inner_main_left_priceZalogWrapper_zalog">
                                <h3 className="adminItem_inner_main_left_priceZalogWrapper_zalog_title">Залог</h3>
                                <input
                                    type="text"
                                    className="adminItem_inner_main_left_priceZalogWrapper_zalog_input"
                                    onChange={e => handleChangeFields(e.target)}
                                    name='zalog'
                                    value={fields.zalog}
                                />
                            </div>
                        </div>
                        <div className="adminItem_inner_main_left_catType adminItem_inner_main_left_catType_ru">
                            <div className="adminItem_inner_main_left_catType_left">
                                <p className="adminItem_inner_main_left_catType_left_name">Категория:</p>
                                <div className="adminItem_inner_main_left_catType_left_dd">
                                    <p className="adminItem_inner_main_left_catType_left_dd_selected">{category}</p>
                                    <div className="adminItem_inner_main_left_catType_left_dd_list"
                                         style={{display: isOpenCatDD ? 'block' : 'none'}}>
                                        {data.category.map(e =>
                                            <li key={e} className="adminItem_inner_main_left_catType_left_dd_list_el"
                                                onClick={() => handleChooseCategory(e)}>
                                                {e}
                                            </li>)}
                                    </div>
                                </div>
                                <img
                                    className='adminItem_inner_main_left_catType_left_img'
                                    src="/images/arrowSort.svg"
                                    alt=""
                                    onClick={handleOpenCatDD}
                                />
                                <span className='required-field'>&#9432;</span>
                            </div>
                            <div className="adminItem_inner_main_left_catType_right">
                                <p className="adminItem_inner_main_left_catType_right_name">Тип:</p>
                                <div className="adminItem_inner_main_left_catType_right_dd">
                                    <p className="adminItem_inner_main_left_catType_right_dd_selected">{type}</p>
                                    <div className="adminItem_inner_main_left_catType_right_dd_list"
                                         style={{display: isOpenTypeDD ? 'block' : 'none'}}>
                                        {data.type.map(e =>
                                            <li key={e} className="adminItem_inner_main_left_catType_right_dd_list_el"
                                                onClick={() => handleChooseType(e)}>
                                                {e}
                                            </li>)}
                                    </div>
                                </div>
                                <img
                                    className='adminItem_inner_main_left_catType_right_img'
                                    src="/images/arrowSort.svg"
                                    alt=""
                                    onClick={handleOpenTypeDD}/>
                                <span className='required-field'>&#9432;</span>
                            </div>
                        </div>
                        <div className="adminItem_inner_main_left_descWrapper">
                            <h3 className="adminItem_inner_main_left_descWrapper_title">
                                Описание
                                <span className='required-field'>&#9432;</span>
                            </h3>
                            <textarea
                                className="adminItem_inner_main_left_descWrapper_input"
                                onChange={e => handleChangeDescription(e.target)}
                                value={description}
                            />
                        </div>
                        <div className="adminItem_inner_main_left_genderWrapper">
                            <p className="adminItem_inner_main_left_genderWrapper_name">Пол:</p>
                            <div className="adminItem_inner_main_left_genderWrapper_dd">
                                <p className="adminItem_inner_main_left_genderWrapper_dd_selected">{gender}</p>
                                <div className="adminItem_inner_main_left_genderWrapper_dd_list"
                                     style={{display: isOpenGenderDD ? 'block' : 'none'}}>
                                    {data.gender.map(e =>
                                        <li key={e} className="adminItem_inner_main_left_genderWrapper_dd_list_el"
                                            onClick={() => handleChooseGender(e)}>
                                            {e}
                                        </li>)}
                                </div>
                            </div>
                            <img
                                className='adminItem_inner_main_left_genderWrapper_img'
                                src="/images/arrowSort.svg"
                                alt=""
                                onClick={handleOpenGenderDD}/>
                            <span className='required-field'>&#9432;</span>
                        </div>
                        <div className="adminItem_inner_main_left_additional">
                            <h3 className="adminItem_inner_main_left_additional_title">Дополнительная информация</h3>
                            <ul className="adminItem_inner_main_left_additional_list">
                                {specifications.map((e, idx) =>
                                    <li key={e.id} className="adminItem_inner_main_left_additional_list_el">
                                        <input
                                            type="text"
                                            className="adminItem_inner_main_left_additional_list_el_name"
                                            name='name'
                                            onChange={e => handleChangeSpecification(e.target, idx)}
                                            value={e.name}
                                        />
                                        <span>:</span>
                                        <input
                                            type="text"
                                            className="adminItem_inner_main_left_additional_list_el_text"
                                            name='text'
                                            onChange={e => handleChangeSpecification(e.target, idx)}
                                            value={e.text}
                                        />
                                        <button className="adminItem_inner_main_left_additional_list_el_cancel"
                                                onClick={() => handleDeleteSpecifications(idx)}
                                        >
                                            <img src="/images/cancel.svg" alt=""
                                                 className="adminItem_inner_main_left_additional_list_el_cancel_img"/>
                                        </button>
                                    </li>)}
                            </ul>
                            <div className="adminItem_inner_main_left_additional_addWrapper">
                                <button className='adminItem_inner_main_left_additional_addWrapper_btn'
                                        onClick={handleAddSpecification}>
                                    <img className='adminItem_inner_main_left_additional_addWrapper_btn_img'
                                         src="/images/plusAdmin.svg" alt=""/>
                                    Добавить
                                </button>
                            </div>
                        </div>
                        <div className="adminItem_inner_main_left_characteristic">
                            <h3 className="adminItem_inner_main_left_characteristic_title">Характеристики</h3>
                            <ul className="adminItem_inner_main_left_characteristic_list">
                                {additionalInfo.map((e: any, idx) =>
                                    <li key={`e.name${idx}`}
                                        className="adminItem_inner_main_left_characteristic_list_el">
                                        <input type="text"
                                               className="adminItem_inner_main_left_characteristic_list_el_name"
                                               name='name'
                                               onChange={e => handleChangeAdditionalInfo(e.target, idx)}
                                               value={e.name}
                                        />
                                        <span>:</span>
                                        <input type="text"
                                               className="adminItem_inner_main_left_characteristic_list_el_text"
                                               name='text'
                                               onChange={e => handleChangeAdditionalInfo(e.target, idx)}
                                               value={e.text}
                                        />
                                        <button className="adminItem_inner_main_left_characteristic_list_el_cancel"
                                                onClick={() => handleDeleteAdditionalInfo(idx)}>
                                            <img src="/images/cancel.svg" alt=""
                                                 className="adminItem_inner_main_left_characteristic_list_el_cancel_img"/>
                                        </button>
                                    </li>)}
                            </ul>
                            <div className="adminItem_inner_main_left_characteristic_addWrapper">
                                <button className='adminItem_inner_main_left_characteristic_addWrapper_btn'
                                        onClick={handleAddAdditionalInfo}>
                                    <img className='adminItem_inner_main_left_characteristic_addWrapper_btn_img'
                                         src="/images/plusAdmin.svg" alt=""/>
                                    Добавить
                                </button>
                            </div>
                        </div>
                        <div className="adminItem_inner_main_left_catType adminItem_inner_main_left_catType_ua">
                            <div className="adminItem_inner_main_left_catType_left">
                                <p className="adminItem_inner_main_left_catType_left_name">Категория:</p>
                                <div className="adminItem_inner_main_left_catType_left_dd">
                                    <p className="adminItem_inner_main_left_catType_left_dd_selected">{ukrCategory}</p>
                                    <div className="adminItem_inner_main_left_catType_left_dd_list"
                                         style={{display: isOpenUkrCatDD ? 'block' : 'none'}}>
                                        {data.ukrCategory.map(e =>
                                            <li key={e} className="adminItem_inner_main_left_catType_left_dd_list_el"
                                                onClick={() => handleChooseUkrCategory(e)}>
                                                {e}
                                            </li>)}
                                    </div>
                                </div>
                                <img
                                    className='adminItem_inner_main_left_catType_left_img'
                                    src="/images/arrowSort.svg"
                                    alt=""
                                    onClick={handleOpenUkrCatDD}
                                />
                                <span className='required-field'>&#9432;</span>
                            </div>
                            <div className="adminItem_inner_main_left_catType_right">
                                <p className="adminItem_inner_main_left_catType_right_name">Тип:</p>
                                <div className="adminItem_inner_main_left_catType_right_dd">
                                    <p className="adminItem_inner_main_left_catType_right_dd_selected">{ukrType}</p>
                                    <div className="adminItem_inner_main_left_catType_right_dd_list"
                                         style={{display: isOpenUkrTypeDD ? 'block' : 'none'}}>
                                        {data.ukrType.map(e =>
                                            <li key={e} className="adminItem_inner_main_left_catType_right_dd_list_el"
                                                onClick={() => handleChooseUkrType(e)}>
                                                {e}
                                            </li>)}
                                    </div>
                                </div>
                                <img
                                    className='adminItem_inner_main_left_catType_right_img'
                                    src="/images/arrowSort.svg"
                                    alt=""
                                    onClick={handleOpenUkrTypeDD}
                                />
                                <span className='required-field'>&#9432;</span>
                            </div>
                        </div>
                        <div className="adminItem_inner_main_left_descWrapper">
                            <h3 className="adminItem_inner_main_left_descWrapper_title">
                                Описание
                                <span className='required-field'>&#9432;</span>
                            </h3>
                            <textarea
                                className="adminItem_inner_main_left_descWrapper_input"
                                onChange={e => handleChangeUkrDescription(e.target)}
                                value={ukrDescription}
                            />
                        </div>
                        <div className="adminItem_inner_main_left_genderWrapper">
                            <p className="adminItem_inner_main_left_genderWrapper_name">Пол:</p>
                            <div className="adminItem_inner_main_left_genderWrapper_dd">
                                <p className="adminItem_inner_main_left_genderWrapper_dd_selected">{ukrGender}</p>
                                <div className="adminItem_inner_main_left_genderWrapper_dd_list"
                                     style={{display: isOpenUkrGenderDD ? 'block' : 'none'}}>
                                    {data.ukrGender.map(e =>
                                        <li key={e} className="adminItem_inner_main_left_genderWrapper_dd_list_el"
                                            onClick={() => handleChooseUkrGender(e)}>
                                            {e}
                                        </li>)}
                                </div>
                            </div>
                            <img
                                className='adminItem_inner_main_left_genderWrapper_img'
                                src="/images/arrowSort.svg"
                                alt=""
                                onClick={handleOpenUkrGenderDD}
                            />
                            <span className='required-field'>&#9432;</span>
                        </div>
                        <div className="adminItem_inner_main_left_additional">
                            <h3 className="adminItem_inner_main_left_additional_title">Дополнительная информация</h3>
                            <ul className="adminItem_inner_main_left_additional_list">
                                {ukrSpecifications.map((e, idx) =>
                                    <li key={e.id} className="adminItem_inner_main_left_additional_list_el">
                                        <input
                                            type="text"
                                            className="adminItem_inner_main_left_additional_list_el_name"
                                            name='name'
                                            onChange={e => handleChangeUkrSpecification(e.target, idx)}
                                            value={e.name}
                                        />
                                        <span>:</span>
                                        <input
                                            type="text"
                                            className="adminItem_inner_main_left_additional_list_el_text"
                                            name='text'
                                            onChange={e => handleChangeUkrSpecification(e.target, idx)}
                                            value={e.text}
                                        />
                                        <button className="adminItem_inner_main_left_additional_list_el_cancel"
                                                onClick={() => handleDeleteUkrSpecifications(idx)}
                                        >
                                            <img src="/images/cancel.svg" alt=""
                                                 className="adminItem_inner_main_left_additional_list_el_cancel_img"/>
                                        </button>
                                    </li>)}
                            </ul>
                            <div className="adminItem_inner_main_left_additional_addWrapper">
                                <button className='adminItem_inner_main_left_additional_addWrapper_btn'
                                        onClick={handleAddUkrSpecification}>
                                    <img className='adminItem_inner_main_left_additional_addWrapper_btn_img'
                                         src="/images/plusAdmin.svg" alt=""/>
                                    Добавить
                                </button>
                            </div>
                        </div>
                        <div className="adminItem_inner_main_left_characteristic">
                            <h3 className="adminItem_inner_main_left_characteristic_title">Характеристики</h3>
                            <ul className="adminItem_inner_main_left_characteristic_list">
                                {ukrAdditionalInfo.map((e: any, idx) =>
                                    <li key={`e.name${idx}`}
                                        className="adminItem_inner_main_left_characteristic_list_el">
                                        <input type="text"
                                               className="adminItem_inner_main_left_characteristic_list_el_name"
                                               name='name'
                                               onChange={e => handleChangeUkrAdditionalInfo(e.target, idx)}
                                               value={e.name}
                                        />
                                        <span>:</span>
                                        <input type="text"
                                               className="adminItem_inner_main_left_characteristic_list_el_text"
                                               name='text'
                                               onChange={e => handleChangeUkrAdditionalInfo(e.target, idx)}
                                               value={e.text}
                                        />
                                        <button className="adminItem_inner_main_left_characteristic_list_el_cancel"
                                                onClick={() => handleDeleteUkrAdditionalInfo(idx)}>
                                            <img src="/images/cancel.svg" alt=""
                                                 className="adminItem_inner_main_left_characteristic_list_el_cancel_img"/>
                                        </button>
                                    </li>)}
                            </ul>
                            <div className="adminItem_inner_main_left_characteristic_addWrapper">
                                <button className='adminItem_inner_main_left_characteristic_addWrapper_btn'
                                        onClick={handleAddUkrAdditionalInfo}>
                                    <img className='adminItem_inner_main_left_characteristic_addWrapper_btn_img'
                                         src="/images/plusAdmin.svg" alt=""/>
                                    Добавить
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="adminItem_inner_main_right">
                        <div className="adminItem_inner_main_right_imgWrapper">
                            <div className="adminItem_inner_main_right_imgWrapper_header">
                                <h2 className="adminItem_inner_main_right_imgWrapper_header_title">Изображение</h2>
                                <div className="adminItem_inner_main_right_imgWrapper_header_addWrapper">
                                    <div className='adminItem_inner_main_right_imgWrapper_header_addWrapper_btn'
                                         onClick={() => {
                                         }}>
                                        <img className='adminItem_inner_main_right_imgWrapper_header_addWrapper_btn_img'
                                             src="/images/plusAdmin.svg" alt=""/>
                                        <label
                                            className="adminItem_inner_main_right_imgWrapper_header_addWrapper_btn_input">
                                            <input
                                                className='adminItem_inner_main_right_imgWrapper_header_addWrapper_btn_input_upload'
                                                type="file"
                                                placeholder={'Загрузить'}
                                                onChange={e => handleImageChange(e)}
                                            />
                                            Загрузить
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="adminItem_inner_main_right_imgWrapper_imagesWrapper">
                                <ul className="adminItem_inner_main_right_imgWrapper_imagesWrapper_list">
                                    {downloadFile.length > 0 ? downloadFile.map((e: any, idx) => <li
                                        key={idx}
                                        className="adminItem_inner_main_right_imgWrapper_imagesWrapper_list_li">
                                        <img
                                            src={e.imagePreview}
                                            alt=""
                                            className="adminItem_inner_main_right_imgWrapper_imagesWrapper_list_li_img"/>
                                    </li>) : <></>}
                                </ul>
                            </div>
                        </div>
                        <div className="adminItem_inner_main_right_vars">
                            <h2 className="adminItem_inner_main_right_vars_title">Варианты товара</h2>
                            <ul className="adminItem_inner_main_left_additional_list">
                                <li className="adminItem_inner_main_left_additional_list_el">
                                    <input
                                        type="text"
                                        className="adminItem_inner_main_left_additional_list_el_name"
                                        value={vars.name}
                                        disabled
                                        name={'name'}
                                        onChange={e => handleChangeVars(e.target)}
                                    />
                                    <span>:</span>
                                    <input
                                        type="text"
                                        className="adminItem_inner_main_left_additional_list_el_text"
                                        name={'text'}
                                        value={vars.text}
                                        onChange={e => handleChangeVars(e.target)}
                                    />
                                    <span>:</span>
                                    <input
                                        type="text"
                                        className="adminItem_inner_main_left_additional_list_el_quantity"
                                        name={'quantity'}
                                        value={vars.quantity}
                                        onChange={e => handleChangeVars(e.target)}
                                    />
                                    {/*<button className="adminItem_inner_main_left_additional_list_el_cancel"*/}
                                    {/*        onClick={() => handleDeleteVars(e.name)}>*/}
                                    {/*    <img src="/images/cancel.svg" alt=""*/}
                                    {/*         className="adminItem_inner_main_left_additional_list_el_cancel_img"/>*/}
                                    {/*</button>*/}
                                </li>
                            </ul>
                            {/*<div className="adminItem_inner_main_left_additional_addWrapper">*/}
                            {/*    <button className='adminItem_inner_main_left_additional_addWrapper_btn'*/}
                            {/*            onClick={() => {*/}
                            {/*            }}>*/}
                            {/*        <img className='adminItem_inner_main_left_additional_addWrapper_btn_img'*/}
                            {/*             src="/images/plusAdmin.svg" alt=""/>*/}
                            {/*        Добавить*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className="adminItem_inner_main_right_status">*/}
                        {/*    <p className="adminItem_inner_main_right_status_text">Статус товара</p>*/}
                        {/*    <div className="adminItem_inner_main_right_status_dd">*/}
                        {/*        <p className="adminItem_inner_main_right_status_dd_selected">{category}</p>*/}
                        {/*        <div className="adminItem_inner_main_right_status_dd_list" style={{display: isOpenCatDD ? 'block' : 'none'}}>*/}
                        {/*            {data.category.map( e =>*/}
                        {/*                <li className="adminItem_inner_main_right_status_dd_list_el" onClick={() => handleChooseCategory(e)}>*/}
                        {/*                    {e}*/}
                        {/*                </li>)}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <img className='adminItem_inner_main_right_status_img' src="/images/arrowSort.svg" alt="" onClick={handleOpenCatDD}/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default AdminItemPage;
