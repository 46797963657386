import { CartData } from '../constants';
import * as _ from "lodash";
import { CartActions ,CartType} from "../actions/cartActions";

const initialState = {
    data: [],
}

export const cartReducer = (state: CartData = initialState, action: CartType): CartData => {
    switch (action.type) {
        case CartActions.ADD_CART_ITEM:
            return {
                ...state,
                data: _.unionBy([...state.data, action.payload], 'id.id')
            }
        case CartActions.DELETE_CART_ITEM:
            return {
                ...state,
                data: state.data.filter((e: any) => e.id.id !== action.payload.id)
            }
        case CartActions.CLEAR_CART:
            return {
                ...state,
                data: []
            }
        default:
            return state
    }
}
