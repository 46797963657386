// CORE
import React, {useEffect, useState} from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import ruLocale from "date-fns/locale/ru";
// COMPONENTS
import TextField from "@material-ui/core/TextField";
import {
    DateRange,
    DateRangePicker,
    LocalizationProvider,
    MobileDateRangePicker
} from "@material-ui/pickers";
// STYLES
import './style.scss';

export default function BasicDateRangePicker(props: { innerWidth: number, idAdminPicker?: boolean }) {

    const lang = useSelector((state: RootStateOrAny) => state.lang.lang);

    const {innerWidth} = props;
    const [selectedDate, setSelectedDate] = useState<DateRange<Date>>([null, null]);
    const [calendarField, setCalendarField] = useState({start: '', end: ''});

    useEffect(() => {
        const period: {}[] = [];

        if(selectedDate[0] !== null) {
            // @ts-ignore
            selectedDate.forEach(time => time !== null && period.push(Date.parse(time)));
        }

        if(selectedDate[1] !== null) {
            props.idAdminPicker ?
                sessionStorage.setItem('dataPeriodAdmin', JSON.stringify([period[0], period[1]])) :
                sessionStorage.setItem('dataPeriod', JSON.stringify([period[0], period[1]]));
        }
    }, [selectedDate])

    useEffect(() => {
        const datePeriodSS = JSON.parse(sessionStorage.getItem('dataPeriod') as string);
        const datePeriodAdminSS = JSON.parse(sessionStorage.getItem('dataPeriodAdmin') as string);

        if(props.idAdminPicker) {
            if(datePeriodAdminSS && typeof datePeriodAdminSS[1] === 'number') {
                setSelectedDate([new Date(datePeriodAdminSS[0]), new Date(datePeriodAdminSS[1])])
            }
        } else {
            if(datePeriodSS && typeof datePeriodSS[1] === 'number') {
                setSelectedDate([new Date(datePeriodSS[0]), new Date(datePeriodSS[1])])
            }
        }
    }, [])

    useEffect(() => {
        lang === 'UA' ? setCalendarField({start: "День видачі", end: "День повернення"}) :
            setCalendarField({start: "День выдачи", end: "День возврата"})
    }, [lang])

    return (
        <LocalizationProvider dateAdapter={DateFnsUtils} locale={ruLocale}>
            {innerWidth < 760 ? <MobileDateRangePicker
                    mask={"__.__.____"}
                    startText={calendarField.start}
                    endText={calendarField.end}
                    value={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    renderInput={(startProps, endProps) => (
                        <>
                            <TextField {...startProps} />
                            <TextField {...endProps} />
                        </>
                    )}
                /> :
                <DateRangePicker
                    mask={"__.__.____"}
                    startText={calendarField.start}
                    endText={calendarField.end}
                    value={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    renderInput={(startProps, endProps) => (
                        <>
                            <TextField {...startProps} />
                            <TextField {...endProps} />
                        </>
                    )}
                />}
        </LocalizationProvider>
    );
}
