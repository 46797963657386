import {createStore, applyMiddleware} from "redux";
import {routerMiddleware} from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory} from 'history';
import {save, load, combineLoads} from "redux-localstorage-simple";
import {composeWithDevTools} from 'redux-devtools-extension';

import rootReducer from "../reducers";
import rootSaga from '../sagas';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const createStoreWithMiddleware
    = composeWithDevTools(applyMiddleware(
    save({states: ['cart'], namespace: 'redux'}),
    sagaMiddleware,
    routerMiddleware(history))
)(createStore)

const store = createStoreWithMiddleware(
    rootReducer,
    combineLoads(
        load({states: ['cart'], namespace: 'redux'}),
    )
)

sagaMiddleware.run(rootSaga);

export default store;
