import { DataActions, DataType } from "../actions/dataAction";
import { Data } from '../constants';
import * as _ from "lodash";

const initialState = {
    data: [],
    currentPage: 0,
    totalPages: 0,
    totalElements: 0,
    cartData: [],
    item: [],
    itemForDiscount: {},
    sameItemsForType: [],
    singleItemLoad: [],
    allSizes: [],
    amountOfStandards: 0,
    amountOfPremiums: 0,
    amountOfWSizes: 0,
    amountOfMSizes: 0,
    amountOfCSizes: 0,
    amountOfSizes: [],
    isLoading: false
}

export const dataReducer = (state: Data = initialState, action: DataType): Data => {
    switch (action.type) {
        case DataActions.PUT_IS_LOADING_INDICATOR:
            return {
                ...state,
                isLoading: true
            }
        case DataActions.PUT_DATA:
            return {
                ...state,
                data: action.payload.data,
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                sameItemsForType: [],
                totalElements: action.payload.totalElements,
                isLoading: false
            }
        case DataActions.ADD_ITEM:
            return {
                ...state,
                item: action.payload
            }
        case DataActions.DELETE_ITEM:
            return {
                ...state,
                item: []
            }
        case DataActions.PUT_ITEM_FOR_DISCOUNT:
            return {
                ...state,
                itemForDiscount: action.payload
            }
        case DataActions.PUT_SAME_ITEMS:
            return {
                ...state,
                sameItemsForType: action.payload
            }
        case DataActions.PUT_ALL_ITEMS_FOR_FILTER:
            return {
                ...state,
                allSizes: action.payload.allSizes,
                amountOfStandards: action.payload.amountOfStandards,
                amountOfPremiums: action.payload.amountOfPremiums,
                amountOfWSizes: action.payload.amountOfWSizes,
                amountOfMSizes: action.payload.amountOfMSizes,
                amountOfCSizes: action.payload.amountOfCSizes,
                amountOfSizes: action.payload.amountOfSizes
            }
        case DataActions.PUT_ITEM_FROM_START:
            return {
                ...state,
                singleItemLoad: action.payload
            }
        case DataActions.DELETE_ITEM_FROM_START:
            return {
                ...state,
                singleItemLoad: []
            }
        default:
            return state
    }
}
