import {PaginationAction, PaginationType} from "../actions/paginationAction";
const initialState = {
    currentPage: 0,
    currentAdminPage: 0
};

export const paginationReducer = (state = initialState, action: PaginationType) => {
    switch (action.type) {
        case PaginationAction.SET_PAGE_NUMBER:
            return {
                ...state,
                currentPage: action.payload
            }
        case PaginationAction.SET_ADMIN_PAGE_NUMBER:
            return {
                ...state,
                currentAdminPage: action.payload
            }
        default:
            return state
    }
}