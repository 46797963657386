import React from 'react';

import './style.scss';

interface IButton {
    textBtn: string,
    onClick?: () => void
    isCart?: boolean,
    type?: any,
    className?: object,
    btnClassName?: object,
    isLiqpayPaymentMethod?: boolean
}

const Button: React.FC<IButton> = ({textBtn, onClick, isCart, type, className, btnClassName, isLiqpayPaymentMethod = false}) => {
    return (
        <div className='btn' style={className}>
            <div className="btn_inner">
                <button
                    type={type ? type : 'button'}
                    className={`btn_main ${isCart && 'btn_main_cart'}` }
                    onClick={onClick}
                    style={btnClassName}
                >
                    {isLiqpayPaymentMethod && <img src="/images/symbol_white.svg" className='btn_main_liqpayImg' alt="liqpay-payment-method"/>}
                    {textBtn}
                </button>
            </div>
        </div>
    );
};

export default Button;
