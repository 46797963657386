import React from 'react';
import LangDropdown from "../Dropdown/Lang";

import './style.scss';

interface IBurgerMenu {
    handleReturnToWebsite: () => void,
    burgerLang: any,
    innerWidth: number
}

const BurgerMenu: React.FC<IBurgerMenu> = ({handleReturnToWebsite, burgerLang, innerWidth}) => {
    const getLink = (link: string) => link.includes('#') ? `/${link}` : link;

    return (
        <div className='burger'>
            <div className="burger_inner">
                <ul className="burger_inner_list">
                    {burgerLang.map((e: any, idx: number) => <>
                        <li
                            key={`${idx.toString()}_${e.text}_${e.link}`}
                            className='burger_inner_list_el'
                            onClick={handleReturnToWebsite}
                        >
                            {e.link.length === 0 ? e.text : <a href={getLink(e.link)}>{e.text}</a>}
                        </li>
                    </>)}
                </ul>
                <div className="burger_inner_phones">
                    <img src="/images/phone.svg" alt=""/>
                    <div className="burger_inner_phones_wrapper">
                        <p key='0'>(044) 456-72-96</p>
                        <p key='1'>(050) 947-60-30</p>
                    </div>
                </div>
                <div className="burger_inner_email">
                    <img src="/images/mail.svg" alt=""/>
                    <div className="burger_inner_email_wrapper">
                        <p key='0'>Email:</p>
                        <p key='1'>modena@ukr.net</p>
                        <a key='2' href="viber://chat?number=%2B380509476030"><img src="/images/viber.svg" alt="viber"/></a>
                        <a key='3' href="whatsapp://send?phone=+380509476030"><img src="/images/whatsup.svg" alt="whatsup"/></a>
                    </div>
                </div>
                {innerWidth <= 480 && <LangDropdown innerWidth={innerWidth}/>}
            </div>
        </div>
    );
};

export default BurgerMenu;
