// CORE
import React, {Suspense, useEffect, useState} from 'react';
import { Router, Redirect, Route, Switch } from "react-router-dom";
import {connect, RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {changeWidth, selectLang} from "../redux/actions";
import {ru, ua} from "../assets/lang";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {history} from '../redux/store';
import _ from "lodash";
// COMPONENTS
import Fallback from '../components/common/fallback';
import Layout from "../containers/Layout";
import CancelOrderPopup from "../components/Popup/CancelOrder";
import AdminItemPage from "../pages/AdminItemPage";
// STYLES
import 'antd/dist/antd.css';

const CatalogPage = React.lazy(() => import('../pages/CatalogPage/index'));
const CardPage = React.lazy(() => import('../pages/CardPage/index'));
const CartPage = React.lazy(() => import('../pages/CartPage/index'));
const Form = React.lazy(() => import('../components/Form/index'));
const AdminPage = React.lazy(() => import('../pages/AdminPage/index'));
const AdminOrderPage = React.lazy(() => import('../pages/AdminOrderPage/index'));
// @ts-ignore
const UserAgreementsPage = React.lazy(() => import('../pages/UserAgreementsPage/UserAgreementsPage.tsx'));

// return to login form and remove LS
const goToLogin = () => {
    localStorage.removeItem("tokens");
    window.location.replace("/login");
};

// install token and if not, go to login page
axios.interceptors.request.use(
    async (config) => {
        let tokens = localStorage.getItem("tokens");

        if (tokens) {
            config.headers.Authorization = `Bearer ${JSON.parse(tokens).accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// check errors
axios.interceptors.response.use(async (response: any) => response,
    async (error: any) => {
        if (error.response) {
            if (error.response.status === 401) {
                goToLogin();
            }

            if (error.response.status === 302) {
                return error.response.data;
            } else if(error.response.status === 500 && error.response.data.errorCode === 31) {
                return error.response.data;
            } else if(error.response.status === 403 && error.response.data.errorCode === 40) {
                return error.response.data;
            } else {
                return Promise.reject(error);
            }
        }
    }
);

function MainRouter() {
    const dispatch = useDispatch();

    const checkToken = (component: any) => {
        let tokens = localStorage.getItem("tokens");

        // @ts-ignore
        const roles = tokens && jwt_decode(JSON.parse(tokens).accessToken).roles;

        return tokens ? (
            roles[0] !== "ADMIN" ? (
                <Redirect to="/" />
            ) : (
                <Redirect to="/admin" />
            )
        ) : (
            component
        );
    };

    const checkAuth = (component: any) => {
        let tokens = localStorage.getItem("tokens");

        if (tokens) {
            const refreshToken: any = jwt_decode(JSON.parse(tokens).refreshToken);

            const isExpiredRefreshToken = Date.now() >= refreshToken.exp * 1000;

            if (isExpiredRefreshToken) {
                localStorage.removeItem("tokens");
                return <Redirect to="/login" />;
            }

            return component;
        }

        if (!tokens) {
            return <Redirect to="/login" />;
        }
    };

    const lang = useSelector((state: RootStateOrAny) => state.lang.lang)

    const [windowWidth, setWindowWidth] = useState({width: 0, height: 0});
    const [isSuccessCancelOrder, setSuccessCancelOrder] = useState(false)
    const [isErrorCancelOrderId, setErrorCancelOrderId] = useState(false)
    const [isErrorCancelOrder, setErrorCancelOrder] = useState(false)
    const [cartLang, setCartLang] = useState({});

    const updateDimensions = () => {
        setWindowWidth({width: window.innerWidth, height: window.innerHeight})
    }

    useEffect(() => {
        window.addEventListener("resize", _.throttle(() => {
            updateDimensions();
        }, 10), {passive: true});
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    useEffect(() => {
        windowWidth.width === 0 ?
            dispatch(changeWidth({width: window.innerWidth, height: window.innerHeight})) :
            dispatch(changeWidth(windowWidth));
    }, [windowWidth, dispatch])

    useEffect(() => {
        if (window.location.href.includes('orderId')) {
            ( async () => {
                const res: any = await axios.delete(`/api/v1/order?orderId=${window.location.search.split('=')[1]}`);

                if(res && res.status === 204) {
                    setSuccessCancelOrder(true);
                } else if(res && res.status === 500 && res.errorCode === 31) {
                    setErrorCancelOrderId(true)
                } else {
                    setErrorCancelOrder(true)
                }
            })();
        } else {
            setSuccessCancelOrder(false);
            setErrorCancelOrderId(false);
            setErrorCancelOrderId(false);
        }
    }, [window.location]);

    useEffect(() => {
        lang === "UA" ?  setCartLang(ua.cart) : setCartLang(ru.cart)
    })

    useEffect(() => {
        const lang: any = localStorage.getItem('lang');

        if(lang) {
            dispatch(selectLang(lang));
        } else {
            dispatch(selectLang("UA"));
            localStorage.setItem('lang', 'UA');
        }

    }, [])

    return (
        <Layout history={history}>
            <Router history={history}>
                <Suspense fallback={<Fallback/>}>
                    <Switch>
                        <Route
                            history={history}
                            path="/login"
                            render={() => checkToken(<Form />)}
                        />
                        <Route exact path="/" component={CatalogPage}/>
                        <Route history={history} exact path="/cart" component={CartPage}>
                            {cartLang && <CartPage cartLang={cartLang}/>}
                        </Route>
                        <Route history={history} exact path="/card/:id" component={CardPage}/>
                        <Route history={history} exact path="/admin" render={() => checkAuth(<AdminPage />)} />
                        <Route history={history} exact path="/admin/item/create" render={() => checkAuth(<AdminItemPage />)} />
                        <Route history={history} exact path="/admin/item/:id" render={() => checkAuth(<AdminItemPage />)} />
                        <Route history={history} exact path="/admin/order/:id" render={() => checkAuth(<AdminOrderPage />)} />
                        <Route history={history} exact path="/user-agreements" render={() => <UserAgreementsPage />} />
                    </Switch>
                </Suspense>
            </Router>
            {(isSuccessCancelOrder || isErrorCancelOrderId || isErrorCancelOrder) &&
                <CancelOrderPopup
                    isErrorCancelOrder={isErrorCancelOrder}
                    isSuccessCancelOrder={isSuccessCancelOrder}
                    isErrorCancelOrderId={isErrorCancelOrderId}
                    handleCloseCancelOrderPopup={() => {
                        setSuccessCancelOrder(false);
                        setErrorCancelOrderId(false);
                        setErrorCancelOrder(false);
                    }}
                />}
        </Layout>
    );
}


export default connect()(MainRouter);


