import {combineReducers} from 'redux';

import {filterReducer} from './filterReducer';
import {dataReducer} from "./dataReducer";
import {routerReducer} from "./routerReducer";
import {paginationReducer} from "./paginationReducer";
import {datePeriodReducer} from "./datePeriodReducer";
import {widthReducer} from "./widthReducer";
import {adminDataReducer} from "./adminDataReducer";
import {langReducer} from "./langReducer";
import { cartReducer } from "./cartReducer";

const rootReducer = combineReducers({
    router: routerReducer,
    data: dataReducer,
    cart: cartReducer,
    filters: filterReducer,
    pagination: paginationReducer,
    datePeriod: datePeriodReducer,
    width: widthReducer,
    adminData: adminDataReducer,
    lang: langReducer
});

export default rootReducer;
