import React, {useEffect, useRef} from 'react';

import './style.scss';
import Map from "../Map";

interface IClientDetailes {
    title: string,
    content: any,
    contacts: any
}

const ClientDetails: React.FC<IClientDetailes> = ({content, title, contacts}) => {
    const conditionRef = useRef<HTMLDivElement>(null);
    const contactsRef = useRef<HTMLDivElement>(null);
    const dateRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#contacts') {
            setTimeout(() => {
                // @ts-ignore
                contactsRef.current.scrollIntoView({behavior: 'smooth'})
            }, 900)
        } else if (hash === '#condition') {
            setTimeout(() => {
                // @ts-ignore
                conditionRef.current.scrollIntoView({behavior: 'smooth'})
            }, 900)
        } else if (hash === '#date') {
            setTimeout(() => {
                // @ts-ignore
                dateRef.current.scrollIntoView({behavior: 'smooth'})
            }, 600)
        }
    }, [conditionRef, contactsRef, dateRef, window.location.hash])

    return (
        <div className='clientDetails'>
            <div className="clientDetails_inner containerMain">
                <div className="clientDetails_conditions" id='condition' ref={conditionRef}>
                    <h2 className="clientDetails_title">{title}</h2>
                    <div className="clientDetails_content">
                        {content.map((f: any) => {
                            return <div key={f.title} className='clientDetails_content_item'>
                                <h3 className='clientDetails_content_item_title'>{f.title}</h3>
                                <div className="clientDetails_content_item_cnt">
                                    {f.isList ?
                                        <ul>{f.content.map((e: any) => {
                                            //@ts-ignore
                                            return <li key={e}>
                                                <span>{e}</span>
                                            </li>
                                        })}
                                        </ul>
                                        : f.content.map((e: any) =>
                                            <p key={e}>{e}</p>
                                        )}
                                    {f.hasOwnProperty('date') &&
                                    //@ts-ignore
                                    <p id='date' style={{
                                        borderBottom: '1px solid #000',
                                        width: 'fit-content',
                                        marginLeft: '20px'
                                    }} ref={dateRef}>
                                        {f.date}
                                    </p>}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className="clientDetails_contacts container" id='contacts' ref={contactsRef}>
                <div className="clientDetails_contacts_info">
                    <h2 className="clientDetails_contacts_title">{contacts.contactTitle}</h2>
                    <div className="clientDetails_contacts_address">
                        <img src="/images/location.svg" alt=""/>
                        <div>
                            <h3>{contacts.contactAddressTitle}</h3>
                            <p>{contacts.contactsAddressInfo}</p>
                        </div>
                    </div>
                    <div className="clientDetails_contacts_time">
                        <img src="/images/clock.svg" alt=""/>
                        <div>
                            <h3>{contacts.contactsTimeTitle}</h3>
                            <p>{contacts.contactsTimeInfo[0]}</p>
                            <p>{contacts.contactsTimeInfo[1]}</p>
                        </div>
                    </div>
                    <div className="clientDetails_contacts_phone">
                        <img src="/images/phone.svg" alt="phone"/>
                        <div>
                            <h3>{contacts.contactsPhoneTitle}</h3>
                            <p>{contacts.contactsFPhone}</p>
                            <p>{contacts.contactsSPhone[0]} <span>{contacts.contactsSPhone[2]}</span></p>
                            <p>{contacts.contactsSPhone[1]}</p>
                        </div>
                    </div>
                    <div className="clientDetails_contacts_email">
                        <img src="/images/mail.svg" alt=""/>
                        <div>
                            <h3>{contacts.contactsEmailTitle}</h3>
                            <p>{contacts.contactsEmailInfo}</p>
                        </div>
                    </div>
                    <div className='clientDetails_contacts_warning'>
                        {contacts.contactsDetails}
                        <ul>
                            <li><span>{contacts.contactsDetailsList[0]}</span></li>
                            <li><span>{contacts.contactsDetailsList[1]}</span></li>
                        </ul>
                    </div>
                </div>
                <div className="clientDetails_contacts_map">
                    <Map/>
                </div>
            </div>
        </div>
    );
};

// @ts-ignore
export default ClientDetails;
