export enum FilterActions {
    ADD_TYPE = "ADD_TYPE",
    DELETE_TYPE = "DELETE_TYPE",
    ASIDE_ADD_CATEGORIES_FILTER = "ASIDE_ADD_CATEGORIES_FILTER",
    ASIDE_ADD_HEIGHTS_FILTER = "ASIDE_ADD_HEIGHTS_FILTER",
    ASIDE_ADD_GENDERS_FILTER = "ASIDE_ADD_GENDERS_FILTER",
    ASIDE_DELETE_CATEGORIES_FILTER = 'ASIDE_DELETE_CATEGORIES_FILTER',
    ASIDE_DELETE_HEIGHTS_FILTER = "ASIDE_DELETE_HEIGHTS_FILTER",
    ASIDE_DELETE_GENDERS_FILTER = "ASIDE_DELETE_GENDERS_FILTER",
    ASIDE_ADD_FILTER_COMP = "ASIDE_ADD_FILTER_COMP",
    ASIDE_DELETE_FILTER_COMP = "ASIDE_DELETE_FILTER_COMP",
    DROP_ASIDE_FILTER_COMP = "DROP_ASIDE_FILTER_COMP",
    SET_SORT_ORDER = 'SET_SORT_ORDER',
    ASIDE_ADD_FILTER_HEIGHTS_COMP = 'ASIDE_ADD_FILTER_HEIGHTS_COMP',
}

// types
interface AddTypeAction {
    type: typeof FilterActions.ADD_TYPE,
    payload: string
}

interface DeleteTypeAction {
    type: typeof FilterActions.DELETE_TYPE,
    payload: string
}

// aside filters
interface AsideAddCategoriesFilterAction {
    type: typeof FilterActions.ASIDE_ADD_CATEGORIES_FILTER,
    payload: string
}

interface AsideAddHeightsFilterAction {
    type: typeof FilterActions.ASIDE_ADD_HEIGHTS_FILTER,
    payload: string
}

interface AsideAddGendersFilterAction {
    type: typeof FilterActions.ASIDE_ADD_GENDERS_FILTER,
    payload: string
}

interface AsideDeleteCategoriesFilterAction {
    type: typeof FilterActions.ASIDE_DELETE_CATEGORIES_FILTER,
    payload: string
}

interface AsideDeleteHeightsFilterAction {
    type: typeof FilterActions.ASIDE_DELETE_HEIGHTS_FILTER,
    payload: string
}

interface AsideDeleteGendersFilterAction {
    type: typeof FilterActions.ASIDE_DELETE_GENDERS_FILTER,
    payload: string
}

// aside filters header line
interface AsideAddFilterActionComp {
    type: typeof FilterActions.ASIDE_ADD_FILTER_COMP,
    payload: string
}

interface AsideAddFilterHeightsActionComp {
    type: typeof FilterActions.ASIDE_ADD_FILTER_HEIGHTS_COMP,
    payload: any
}

interface AsideDeleteFilterActionComp {
    type: typeof FilterActions.ASIDE_DELETE_FILTER_COMP,
    payload: string
}

interface DropAsideFilterActionComp {
    type: typeof FilterActions.DROP_ASIDE_FILTER_COMP
}

interface SetSortOrder {
    type: typeof FilterActions.SET_SORT_ORDER,
    payload: string
}

export type FiltersType =
    AddTypeAction
    | DeleteTypeAction
    | AsideAddCategoriesFilterAction
    | AsideAddHeightsFilterAction
    | AsideAddGendersFilterAction
    | AsideDeleteCategoriesFilterAction
    | AsideDeleteGendersFilterAction
    | AsideDeleteHeightsFilterAction
    | AsideAddFilterActionComp
    | AsideAddFilterHeightsActionComp
    | AsideDeleteFilterActionComp
    | DropAsideFilterActionComp
    | SetSortOrder;
