import {WidthActions, WidthType} from "../actions/widthAction";
const initialState: {width: number, height: number} = {width: 0, height: 0};

export const widthReducer = (state = initialState, action: WidthType) => {
    switch (action.type) {
        case WidthActions.CHANGE_WIDTH:
            return action.payload
        default:
            return state
    }
}