import React, {ReactNode, useEffect, useState} from 'react';
import Header from '../../components/Header/index';
import Breadcrumb from '../../components/Breadcrumb';
import ClientDetails from "../../components/ClientDetails";
import Footer from "../../components/Footer";
import Reservation from "../Reservation";
import {RootStateOrAny, useSelector} from "react-redux";
import {ua, ru} from '../../assets/lang';
interface ILayoutProps {
    children: ReactNode,
    history: any
}

const Layout: React.FC<ILayoutProps> = ({children}) => {
    // state
    const [isShownLayout, setShownLayout] = useState(false);
    const itemsNum = useSelector((state: RootStateOrAny) => state.cart.data.length);
    const router = useSelector((state: RootStateOrAny) => state.router);
    const lang = useSelector((state: RootStateOrAny) => state.lang.lang);

    const [reservationLang, setReservationLang] = useState<{title: string, tip: string}>({title: '', tip: ''});
    const [clientDetailsLang, setClientDetailsLang] = useState<any>({});
    const [headerLang, setHeaderLang] = useState<any>({});
    const [footerLang, setFooterLang] = useState<any>({});
    const [toolsBoardLang, setToolsBoardLang] = useState<any>({});

    useEffect(() => {
        if (router.length > 0) {
            if (router[1].name === 'Оформление заказа' || router[1].name === 'Оформлення замовлення') {
                setShownLayout(false)
            } else if (router.length > 2 && router[2].hasOwnProperty('type')) {
                setShownLayout(false)
            } else if (router[0].name === 'Заказы' || router[0].name === 'Закази') {
                setShownLayout(false)
            } else if (router[0].name === 'Товары' || router[0].name === 'Товари') {
                setShownLayout(false)
            } else if ((router[2] && router[2].name === 'Користувацька угода') || (router[2] && router[2].name === 'Пользовательское соглашение')) {
                setShownLayout(false)
            }else {
                setShownLayout(true)
            }
        }
    }, [router]);

    useEffect(() => {
        lang === 'UA' ? setReservationLang(ua.reservation) : setReservationLang(ru.reservation)
        lang === 'UA' ? setHeaderLang(ua.header) : setHeaderLang(ru.header)
        lang === 'UA' ? setFooterLang(ua.footer) : setFooterLang(ru.footer)
        lang === 'UA' ? setClientDetailsLang({
            title: ua.clientDetails.title,
            content: ua.clientDetails.content,
            contacts: ua.contacts
        }) :
            setClientDetailsLang({
                title: ru.clientDetails.title,
                content: ru.clientDetails.content,
                contacts: ru.contacts
            })
        lang === 'UA' ?
            setToolsBoardLang(
                {
                    textBtn: 'Знайти', equipmentText: 'Тип спорядження',
                    textTypes: ['Вибрати все', 'Лижі', 'Черевики', 'Аксесуари', 'Багажники']
                }) :
            setToolsBoardLang(
                {
                    textBtn: 'Найти', equipmentText: 'Тип снаряжения',
                    textTypes: ['Выбрать все', 'Лыжи', 'Ботинки', 'Аксессуары', 'Багажники']
                })
    }, [lang])

    return (
        <div style={window.location.href.includes('orderId') ?
            {
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            } :
            {}
        }
        >
            {
                window.location.pathname === '/login' ?
                <></> :
                <Header cartNum={itemsNum} isAdmin={window.location.pathname.includes('admin')} {...headerLang}/>
            }
            {window.location.pathname.includes('admin') ? <></> : <Breadcrumb/>}
            {isShownLayout ? <Reservation {...reservationLang} toolsBoardLang={toolsBoardLang}/> : <></>}
            {children}
            {isShownLayout ? <ClientDetails {...clientDetailsLang}/> : <></>}
            {window.location.pathname === '/login' || window.location.pathname.includes('admin')  ? <></> : <Footer {...footerLang}/>}
        </div>
    );
};

// @ts-ignore
export default Layout;
