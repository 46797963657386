import {FilterActions} from "./filterActions";
import {DataActions} from "./dataAction";
import {LangActions} from "./langAction";
import {RouteActions} from "./routerActions";
import {Card} from "../constants";
import {PaginationAction} from "./paginationAction";
import {DatePeriodActions} from "./datePeriodAction";
import {WidthActions} from "./widthAction";
import {AdminDataActions} from "./adminDataAction";
import { CartActions } from "./cartActions";

//sort order
export const setSortOrder = (sort: string) => {
    return {
        type: FilterActions.SET_SORT_ORDER,
        payload: sort
    }
}
//change lang
export const selectLang = (lang: string) => {
    return {
        type: LangActions.SET_LANG,
        payload: lang
    }
}

// add date period
export const addDatePeriod = (e: string[]) => {
    return {
        type: DatePeriodActions.ADD_DATE_PERIOD,
        payload: e
    }
}

export const addAdminDatePeriod = (e: string[]) => {
    return {
        type: DatePeriodActions.ADD_ADMIN_DATE_PERIOD,
        payload: e
    }
}
// filter add/delete actions
export const addType = (e: string) => {
    return {
        type: FilterActions.ADD_TYPE,
        payload: e
    }
}

export const deleteType = (e: string) => {
    return {
        type: FilterActions.DELETE_TYPE,
        payload: e
    }
}

// aside filters
export const addAsideCategoriesFilter = (e: string) => {
    return {
        type: FilterActions.ASIDE_ADD_CATEGORIES_FILTER,
        payload: e
    }
}
export const deleteAsideCategoriesFilter = (e: string) => {
    return {
        type: FilterActions.ASIDE_DELETE_CATEGORIES_FILTER,
        payload: e
    }
}

export const addAsideHeightsFilter = (e: any) => {
    return {
        type: FilterActions.ASIDE_ADD_HEIGHTS_FILTER,
        payload: e
    }
}

export const deleteAsideHeightsFilter = (e: string) => {
    return {
        type: FilterActions.ASIDE_DELETE_HEIGHTS_FILTER,
        payload: e
    }
}

export const addAsideGendersFilter = (e: string) => {
    return {
        type: FilterActions.ASIDE_ADD_GENDERS_FILTER,
        payload: e
    }
}
export const deleteAsideGendersFilter = (e: string) => {
    return {
        type: FilterActions.ASIDE_DELETE_GENDERS_FILTER,
        payload: e
    }
}
// aside filters header line
export const addAsideFilterComp = (e: string) => {
    return {
        type: FilterActions.ASIDE_ADD_FILTER_COMP,
        payload: e
    }
}

export const addAsideFilterHeightsComp = (e: any) => {
    return {
        type: FilterActions.ASIDE_ADD_FILTER_HEIGHTS_COMP,
        payload: e
    }
}

export const deleteAsideFilterComp = (e: string) => {
    return {
        type: FilterActions.ASIDE_DELETE_FILTER_COMP,
        payload: e
    }
}

export const dropAsideFilterActionComp = () => {
    return {
        type: FilterActions.DROP_ASIDE_FILTER_COMP
    }
}

// data actions
export const loadData = (e: {
    startRent: any,
    endRent: any,
    type: string[],
    size: number,
    currentPage: number,
    isAllItems: boolean ,
    categories?: { categories: string[], heights: string[], genders: string[] },
    sort: string,
    lang: string
}) => {
    return {
        type: DataActions.LOAD_DATA,
        payload: {
            startRent: e.startRent,
            endRent: e.endRent,
            type: e.type,
            size: e.size,
            currentPage: e.currentPage,
            isAllItems: e.isAllItems,
            category: e.categories,
            sort: e.sort,
            lang: e.lang
        }
    }
}

export const putData = (dataFromServer: any) => {
    return {
        type: DataActions.PUT_DATA,
        payload: dataFromServer
    }
}

export const loadItemForDiscount = (e: {
    startRent: any,
    endRent: any,
    type: string,
    lang: string
}) => {
    return {
        type: DataActions.LOAD_ITEM_FOR_DISCOUNT,
        payload: {
            startRent: e.startRent,
            endRent: e.endRent,
            type: e.type,
            lang: e.lang
        }
    }
}

export const putItemForDiscount = (e: Card) => {
    return {
        type: DataActions.PUT_ITEM_FOR_DISCOUNT,
        payload: e
    }
}

export const loadAllItemsForFilters = () => {
    return {
        type: DataActions.LOAD_ALL_ITEMS_FOR_FILTER
    }
}

export const putAllItemsForFilters = (data: Card) => {
    return {
        type: DataActions.PUT_ALL_ITEMS_FOR_FILTER,
        payload: data
    }
}

export const loadSameItems = (e: {
    startRent: any,
    endRent: any,
    type: string,
    lang: string
}) => {
    return {
        type: DataActions.LOAD_SAME_ITEMS,
        payload: {
            startRent: e.startRent,
            endRent: e.endRent,
            type: e.type,
            lang: e.lang
        }
    }
}

export const putSameItems = (data: Card[]) => {
    return {
        type: DataActions.PUT_SAME_ITEMS,
        payload: data
    }
}
// load from start
export const loadFromStart = (card: {id: string, lang: string}) => {
    return {
        type: DataActions.LOAD_ITEM_FROM_START,
        payload: {id: card.id, lang: card.lang}
    }
}

export const putFromStart = (data: Card[]) => {
    return {
        type: DataActions.PUT_ITEM_FROM_START,
        payload: data
    }
}

export const deleteItemFromStart = () => {
    return {
        type: DataActions.DELETE_ITEM_FROM_START
    }
}
// load order from start

export const loadOrderFromStart = ( e: { orderId: string; accessToken: string }) => {
    return {
        type: AdminDataActions.LOAD_ORDER_FROM_START,
        payload: {orderId: e.orderId, accessToken: e.accessToken}
    }
}

export const putOrderFromStart = (data: any) => {
    return {
        type: AdminDataActions.PUT_ORDER_FROM_START,
        payload: data
    }
}

export const deleteOrderFromStart = () => {
    return {
        type: AdminDataActions.DELETE_ORDER_FROM_START
    }
}

// load admin item from start
export const loadAdminItemFromStart = (params: {adminId: string, accessToken: string}) => {
    return {
        type: AdminDataActions.LOAD_ADMIN_ITEM_FROM_START,
        payload: {id: params.adminId, accessToken: params.accessToken}
    }
}

export const putAdminItemFromStart = (data: Card[]) => {
    return {
        type: AdminDataActions.PUT_ADMIN_ITEM_FROM_START,
        payload: data
    }
}

export const deleteAdminItemFromStart = () => {
    return {
        type: AdminDataActions.DELETE_ADMIN_ITEM_FROM_START
    }
}

export const deleteOrder = () => {
    return {
        type: AdminDataActions.DELETE_ORDER
    }
}

// data admin actions
export const loadAdminData = (e: { startRent: any, endRent: any }) => {
    return {
        type: AdminDataActions.LOAD_ADMIN_DATA,
        payload: {startRent: e.startRent, endRent: e.endRent}
    }
}

export const putAdminData = (data: any) => {
    return {
        type: AdminDataActions.PUT_ADMIN_DATA,
        payload: data
    }
}

export const chooseAdminItem = (card: Card) => {
    return {
        type: AdminDataActions.CHOOSE_ADMIN_ITEM,
        payload: card
    }
}

export const loadAdminDataOrders = (e: { startRent: any, endRent: any, currentPage: any }) => {
    return {
        type: AdminDataActions.LOAD_ADMIN_DATA_ORDERS,
        payload: {startRent: e.startRent, endRent: e.endRent, currentPage: e.currentPage}
    }
}

export const putAdminDataOrders = (data: any) => {
    return {
        type: AdminDataActions.PUT_ADMIN_DATA_ORDERS,
        payload: data
    }
}

export const chooseAdminOrder = (card: Card) => {
    return {
        type: AdminDataActions.CHOOSE_ADMIN_ORDER,
        payload: card
    }
}

// routes
export const loadRoute = (routes: {}[]) => {
    return {
        type: RouteActions.LOAD_ROUTE,
        payload: routes
    }
}

export const putRoute = (routes: {}[]) => {
    return {
        type: RouteActions.PUT_ROUTE,
        payload: routes
    }
}

//cart page
export const loadCartItem = (card: any) => {
    return {
        type: CartActions.ADD_CART_ITEM,
        payload: card
    }
}

export const deleteCartItem = (card: any) => {
    return {
        type: CartActions.DELETE_CART_ITEM,
        payload: card
    }
}

export const clearCart = () => {
    return {
        type: CartActions.CLEAR_CART,
    }
}

export const chooseItem = (card: Card | undefined) => {
    return {
        type: DataActions.ADD_ITEM,
        payload: card
    }
}

export const deleteItem = () => {
    return {
        type: DataActions.DELETE_ITEM,
    }
}

//pagination
export const setPageNumber = (num: number) => {
    return {
        type: PaginationAction.SET_PAGE_NUMBER,
        payload: num
    }
}

export const setAdminPageNumber = (num: number) => {
    return {
        type: PaginationAction.SET_ADMIN_PAGE_NUMBER,
        payload: num
    }
}

//width
export const changeWidth = (options: { width: number, height: number }) => {
    return {
        type: WidthActions.CHANGE_WIDTH,
        payload: options
    }
}

// set isLoading indicator
export const setIsLoadingIndicator = () => {
    return {
        type: DataActions.PUT_IS_LOADING_INDICATOR
    }
}
