import React, {useEffect, useRef, useState} from 'react';

import {selectLang} from "../../../redux/actions";
import {useDispatch} from "react-redux";

import './style.scss';

const langs = ['UA', 'RU'];

const LangDropdown: React.FC<{innerWidth: number}> = ({innerWidth}) => {
    const dispatch = useDispatch();

    //auto close DD
    const wrapperRef = useRef(null);
    const [selectedLang, setSelectedLang] = useState('RU')
    const [isOpen, setOpen] = useState(false);

    const handleChangeLang = (lang: string) => {
        setOpen(false);
        setSelectedLang(lang);

        localStorage.setItem('lang', lang)
        dispatch(selectLang(lang));
    }

    const handleOpenDD = () => setOpen(!isOpen);

    const handleClickOutside = (event: any) => {
        // @ts-ignore
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            return setOpen(false);
        }
    };

    useEffect(() => {
        const lang: any = localStorage.getItem('lang');
        dispatch(selectLang(lang));
        setSelectedLang(lang);
    }, [localStorage, selectedLang])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, [wrapperRef]);

    return (
        <div className='dropdownLang' ref={wrapperRef}>
            <div className="dropdownLang_inner" onClick={handleOpenDD}>
                {innerWidth > 480 ? <>
                    <div className="dropdownLang_langsOver">
                        <p className="dropdownLang_selectedLang">{selectedLang}</p>
                        {isOpen &&
                            <div className="dropdownLang_langs" style={{display: isOpen ? 'flex' : 'none'}}>
                                {langs.map((p, idx) =>
                                    <div
                                        key={`${idx.toString()}_${p}`}
                                        onClick={() => handleChangeLang(p)}
                                        className="dropdownLang_lang"
                                    >
                                        {p}
                                    </div>)}
                        </div>
                        }
                    </div>
                    <img
                        className='dropdownLang_arrow'
                        src='/images/arrow.svg'
                        alt="arrow"
                        style={{transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}}
                    />
                    </> :
                    <>
                        <div className="dropdownLang_langsOver_mobile">
                                {langs.map((p, idx) =>
                                    <div
                                        key={`${idx.toString()}_${p}`}
                                        onClick={() => handleChangeLang(p)}
                                        className={`dropdownLang_langsOver_mobile_lang${selectedLang === p ? '-selected' : ''}`}
                                    >
                                        {p}
                                    </div>
                                )}
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default LangDropdown;
