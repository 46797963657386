import React, {useEffect, useRef, useState} from 'react';

import './phoneDropdown.scss';

const phonesArr = ['(050) 947-60-30', '(044) 456-72-96'];

const PhoneDropdown = () => {
    const [isOpenDD, setOpenDD] = useState(false)
    const handleOpenDD = () => setOpenDD(!isOpenDD);
    //auto close DD
    const wrapperRef = useRef(null);

    const handleClickOutside = (event: any) => {
        // @ts-ignore
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            return setOpenDD(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, [wrapperRef]);

    return (
        <div className='dropdown' ref={wrapperRef}>
            <div className="dropdown_inner" onClick={handleOpenDD}>
                <div className="dropdown_phonesOver">
                    <img src="/images/phone.svg" alt=""/>
                    <p className="dropdown_selectedPhone">(050) 947-60-30</p>
                    {isOpenDD && <div className="dropdown_phones" style={{display: isOpenDD ? 'flex' : 'none'}}>
                        {phonesArr.map(p => <div key={p} className="dropdown_phone">{p}</div>)}
                    </div>}
                </div>
                <img
                    className='dropdown_arrow'
                    src='/images/arrow.svg'
                    alt="arrow"
                    style={{transform: isOpenDD ? 'rotate(180deg)' : 'rotate(0deg)'}}
                />
            </div>
        </div>
    );
};

export default PhoneDropdown;
