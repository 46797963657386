import React from 'react';

import './style.scss';

interface ICheckbox {
    isChecked?: boolean,
    text: string,
    inputName: string,
    isChooseCard?: boolean,
    classname?: string,
    handleChooseItem?: (e: string, event: any) => void,
    onClick?: (e: any) => void,
    handleOpenEquipDD: () => void
}

const Checkbox: React.FC<ICheckbox> = (
    {
        text,
        inputName,
        classname,
        handleChooseItem,
        isChecked,
        handleOpenEquipDD,
    }) => {

    const handlePushItem = (event: any) => {
        if (handleChooseItem) {
            if (event.target.checked) {
                handleChooseItem(event.target.name, event.target);
                handleOpenEquipDD()

            } else {
                handleChooseItem(event.target.name, event.target)
            }
        }
    }

    return (
        <div className='checkbox'>
            <div className={`checkbox_inner ${classname}`}>
                <label
                    className='checkbox_inner_label'
                    style={{
                        background: isChecked ? 'linear-gradient(0deg, #ECF5FF, #ECF5FF)' : '#fff',
                        // @ts-ignore
                        "--checkbox-color": isChecked ? "#005FE3" : '#fff',
                        "--border-color": isChecked ? "#005FE3" : '#DFE0EC',
                    }}
                >
                    <>
                        <input
                            className='checkbox_inner_input'
                            name={inputName}
                            checked={true}
                            onClick={(e) => handlePushItem(e)}
                            onChange={() => {}}
                        /> {text}
                    </>
                </label>
            </div>
        </div>
    );
};

export default Checkbox;
