import * as React from 'react';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {history} from "../../redux/store";
import {addType} from "../../redux/actions";

import './style.scss';

const Breadcrumb = () => {
    const dispatch = useDispatch();

    const routes = useSelector((state: RootStateOrAny) => state.router);
    const lang = useSelector((state: RootStateOrAny) => state.lang.lang);

    const handleLoadDataBySort = (type: string) => {
        if(type !== 'Заказы' && type !== 'Товары') {
            dispatch(addType(type === "Прокат" || type === 'Главная' || type === 'Головна' ? lang === "UA" ? 'вибрати все' : 'выбрать все' : type.toLowerCase()));
            history.push('/');
        }else {
            history.push('/admin');
        }
    }

    return (
        <div className='breadcrumb'>
            <div className="breadcrumb_inner container">
                {routes && routes.map((x: any, key: number) =>
                    key + 1 === routes.length ? (
                        <span className='breadcrumb_last' key={key}>{x.name}</span>) :
                        <button
                            key={key}
                            className='breadcrumb_el'
                            onClick={() => handleLoadDataBySort(x.name)}>
                            {x.name}
                            <span>&gt;</span>
                        </button>
                )}
            </div>
        </div>
    );
};

export default Breadcrumb;
