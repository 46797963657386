import React from 'react';

const Map = () => {
    return (
        <div className='map' style={{height: '100%'}}>
            <div className="map_inner" style={{height: '100%'}}>
                <iframe
                    title='map'
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d635.0288114647282!2d30.4287724!3d50.4575786!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cc37ff33dddf%3A0xbc07fb4f3b7d7442!2z0L_RgNC-0YHQvy4g0J_QvtCx0LXQtNGLLCA2OC8xLCDQmtC40LXQsiwgMDIwMDA!5e0!3m2!1sru!2sua!4v1606684552835!5m2!1sru!2sua"
                    width="600"
                    height="100%"
                    frameBorder="0"
                    style={{border: 0, width: '100%'}}
                    allowFullScreen={true}
                    aria-hidden="false"
                    tabIndex={0}/>
            </div>
        </div>
    );
};

export default Map;
