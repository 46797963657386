import React, {useEffect, useState} from 'react';
import {RootStateOrAny, useSelector} from "react-redux";
import {getNotification} from "../../utils/utils";
import {history} from "../../redux/store";
import {ru, ua} from "../../assets/lang";

import PhoneDropdown from "../Dropdown/phoneDropdown";
import BurgerMenu from "../BurgerMenu";
import LangDropdown from "../Dropdown/Lang";

import './style.scss';

interface IHeaderProps {
    cartNum: number,
    isAdmin: boolean,
    tabs: string[]
}

const Header: React.FC<IHeaderProps> = ({cartNum, isAdmin, tabs}) => {
    const [isOpenBurger, setOpenBurger] = useState(false);

    // REDUX

    const totalElements = useSelector((state: RootStateOrAny) => state.data.totalElements)
    const lang = useSelector((state: RootStateOrAny) => state.lang.lang);
    const innerWidth = useSelector((state: RootStateOrAny) => state.width.width);

    // STATE

    const [burgerLang, setBurgerLang] = useState<any>();
    const [tabR, setTabR] = useState({order: true, items: false})

    // FUNCTIONS

    const handleOpenBurger = () => setOpenBurger(!isOpenBurger)

    const handleOpenCartPage = () => {
        const datePeriodSS = JSON.parse(sessionStorage.getItem('dataPeriod') as string);

        if(cartNum === 0) {
            getNotification('warning',lang === 'UA' ? 'Додайте товар до кошика' : 'Добавьте товар в корзину')
        } else if (cartNum > 0 && datePeriodSS && typeof datePeriodSS[1] === 'number') {
            history.push('/cart');
        } else {
            getNotification('warning',lang === 'UA' ? 'Виберіть дату прокату' : 'Выберите дату проката')
        }
    }
    const handleReturnToWebsite = () => setOpenBurger(false)

    const handleChooseTab = (name: string) => {
        localStorage.setItem('tab', name);
        if (name === 'Заказы') {
            setTabR({order: true, items: false})
            history.push('/admin')
        } else {
            setTabR({order: false, items: true})
            history.push('/admin')
        }
    }

    // return to login form and remove LS
    const handleLogout = () => {
        localStorage.removeItem("tokens");
        window.location.replace("/login");
    };

    // LIFECYCLE

    useEffect(() => {
        lang === 'UA' ? setBurgerLang(ua.burger) : setBurgerLang(ru.burger)
    }, [lang])

    useEffect(() => {
        localStorage.getItem('tab') === 'Товары' ? setTabR({order: false, items: true}) : setTabR({
            items: false,
            order: true
        });
    }, [])

    return (
        <div className='header'>
            <div className="header_inner container">
                <a href="https://msport.com.ua/">
                    <img className='header_logo' src='/images/Logo.svg' alt="logo_modena"
                         onClick={() => history.push('/')}/>
                </a>
                <div className="header_options">
                    <div className="header_left">
                        {
                            !isAdmin ?
                                <>
                                    <a href="#condition" onClick={() => history.push('/#condition')}
                                       className="header_link">{tabs && tabs[0]}</a>
                                    <a href="#contacts" onClick={() => history.push('/#contacts')}
                                       className="header_link">{tabs && tabs[1]}</a>
                                    <a href="https://msport.com.ua/" target='_blank' className="header_link">{tabs && tabs[2]}</a>
                                    <a href="https://msport.com.ua/about-us/" target='_blank' className="header_link">{tabs && tabs[3]}</a>
                                    <button
                                        className="header_link"
                                        onClick={() => history.push('/user-agreements')}
                                    >{tabs && tabs[4]}</button>
                                </> :
                                <>
                                    <button className={`header_link ${tabR.order ? 'header_link_under' : ''}`}
                                       onClick={() => handleChooseTab('Заказы')}>Заказы</button>
                                    <button className={`header_link ${tabR.items ? 'header_link_under' : ''}`}
                                       onClick={() => handleChooseTab('Товары')}>
                                        Товары
                                        <div className="header_link_orderWrapper">
                                            <span className='header_link_order'>{totalElements}</span>
                                        </div>
                                    </button></>}
                    </div>
                    <div className="header_right">
                        {!isAdmin ?
                            <>
                                <PhoneDropdown/>
                                {innerWidth > 480 && <LangDropdown innerWidth={innerWidth}/>}
                                <div className="header_cart_over" onClick={handleOpenCartPage}>
                                    <img src='/images/Cart.svg' alt="cart" className='header_cart'/>
                                    <span className="header_cart_num">{cartNum}</span>
                                </div>
                                <img src={isOpenBurger ? 'images/hamburgerCancel.svg' : '/images/hamburger.svg'}
                                     alt='burger-icon' className='header_burger' onClick={handleOpenBurger}/>
                                {isOpenBurger && <div className="header_burger_wrapper">
                                    <BurgerMenu handleReturnToWebsite={handleReturnToWebsite} burgerLang={burgerLang} innerWidth={innerWidth}/>
                                </div>}
                            </> :
                            <div className='header_right_admin'>
                                <h3 className="header_right_admin_name">Andrii Fursenko (Admin)</h3>
                                <button
                                    className='header_right_admin_btn'
                                    onClick={handleLogout}>Выйти
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
