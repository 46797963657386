import {AdminDataActions, AdminDataType} from "../actions/adminDataAction";
import {AdminData} from '../constants';
import * as _ from "lodash";

const initialState = {
    data: {},
    filteredDataByCategory: [],
    item: {},
    orders: [],
    order: {},
    sortedData: [],
    singleOrderLoad: {},
    singleAdminItemLoad: {}
}
//@ts-ignore
export const adminDataReducer = (state: AdminData = initialState, action: AdminDataType): AdminData => {
    switch (action.type) {
        case AdminDataActions.PUT_ADMIN_DATA:
            return {
                ...state,
                data: action.payload,
                filteredDataByCategory: [],
                sortedData: []
            }
        case AdminDataActions.CHOOSE_ADMIN_ITEM:
            return {
                ...state,
                item: action.payload
            }
        case AdminDataActions.PUT_ADMIN_DATA_ORDERS:
            return {
                ...state,
                orders: action.payload,
                filteredDataByCategory: [],
                sortedData: []
            }
        case AdminDataActions.CHOOSE_ADMIN_ORDER:
            return {
                ...state,
                order: action.payload
            }
        case AdminDataActions.PUT_ORDER_FROM_START:
            return {
                ...state,
                singleOrderLoad: action.payload
            }
        case AdminDataActions.DELETE_ORDER_FROM_START:
            return {
                ...state,
                singleOrderLoad: []
            }
        case AdminDataActions.PUT_ADMIN_ITEM_FROM_START:
            return {
                ...state,
                singleAdminItemLoad: action.payload
            }
        case AdminDataActions.DELETE_ADMIN_ITEM_FROM_START:
            return {
                ...state,
                singleAdminItemLoad: {}
            }
            case AdminDataActions.DELETE_ORDER:
            return {
                ...state,
                item: {}
            }
        default:
            return state
    }
}
