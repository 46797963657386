export enum PaginationAction {
    SET_PAGE_NUMBER= "SET_PAGE_NUMBER",
    SET_ADMIN_PAGE_NUMBER = 'SET_ADMIN_PAGE_NUMBER'
}

interface SetPageNumber {
    type: typeof PaginationAction.SET_PAGE_NUMBER,
    payload: number
}

interface SetAdminPageNumber {
    type: typeof PaginationAction.SET_ADMIN_PAGE_NUMBER,
    payload: number
}

export type PaginationType = SetPageNumber | SetAdminPageNumber;