import {Card} from "../constants";

export enum DataActions {
    LOAD_DATA = "LOAD_DATA",
    PUT_DATA = "PUT_DATA",
    LOAD_ITEM_FOR_DISCOUNT = "LOAD_ITEM_FOR_DISCOUNT",
    PUT_ITEM_FOR_DISCOUNT = "PUT_ITEM_FOR_DISCOUNT",
    LOAD_SAME_ITEMS = "LOAD_SAME_ITEMS",
    PUT_SAME_ITEMS = "PUT_SAME_ITEMS",
    ADD_ITEM = "ADD_ITEM",
    PUT_ITEM_FROM_START = "PUT_ITEM_FROM_START",
    LOAD_ITEM_FROM_START = "LOAD_ITEM_FROM_START",
    DELETE_ITEM_FROM_START = "DELETE_ITEM_FROM_START",
    DELETE_ITEM = "DELETE_ITEM",
    LOAD_ALL_ITEMS_FOR_FILTER = "LOAD_ALL_ITEMS_FOR_FILTER",
    PUT_ALL_ITEMS_FOR_FILTER = "PUT_ALL_ITEMS_FOR_FILTER",
    PUT_IS_LOADING_INDICATOR = "PUT_IS_LOADING_INDICATOR",
}

interface LoadItemFromStart {
    type: typeof DataActions.LOAD_ITEM_FROM_START,
    payload: string
}

interface PutItemFromStart {
    type: typeof DataActions.PUT_ITEM_FROM_START,
    payload: Card[]
}

interface DeleteItemFromStart {
    type: typeof DataActions.DELETE_ITEM_FROM_START
}

interface LoadItemForDiscount {
    type: typeof DataActions.LOAD_ITEM_FOR_DISCOUNT,
    payload: {
        startRent: string,
        endRent: string,
        type: string,
        lang: string
    }
}

interface PutItemForDiscount {
    type: typeof DataActions.PUT_ITEM_FOR_DISCOUNT,
    payload: Card
}

interface LoadAllItemsForFilter {
    type: typeof DataActions.LOAD_ALL_ITEMS_FOR_FILTER
}

interface PutAllItemsForFilter {
    type: typeof DataActions.PUT_ALL_ITEMS_FOR_FILTER,
    payload: {
        allItems: Card[],
        allSizes: string[],
        amountOfStandards: number,
        amountOfPremiums: number,
        amountOfWSizes: number,
        amountOfMSizes: number,
        amountOfCSizes: number,
        amountOfSizes: any
    }
}

interface LoadSameItems {
    type: typeof DataActions.LOAD_SAME_ITEMS,
    payload: {
        startRent: string,
        endRent: string,
        type: string,
        lang: string
    }
}

interface PutSameItems {
    type: typeof DataActions.PUT_SAME_ITEMS,
    payload: Card[]
}

interface LoadData {
    type: typeof DataActions.LOAD_DATA,
    payload: {
        startRent: string,
        endRent: string,
        type: string[],
        currentPage: number,
        size: number,
        category: { categories: string[], heights: string[], genders: string[] },
        sort: string,
        lang: string
    }
}

interface PutData {
    type: typeof DataActions.PUT_DATA,
    payload: any
}

interface ChooseItem {
    type: typeof DataActions.ADD_ITEM,
    payload: Card[]
}

interface DeleteItem {
    type: typeof DataActions.DELETE_ITEM,
}

interface PutIsLoadingIndicator {
    type: typeof DataActions.PUT_IS_LOADING_INDICATOR,
}

export type DataType =
    LoadData
    | PutData
    | LoadItemFromStart
    | PutItemFromStart
    | DeleteItemFromStart
    | LoadItemForDiscount
    | PutItemForDiscount
    | LoadSameItems
    | PutSameItems
    | ChooseItem
    | DeleteItem
    | PutAllItemsForFilter
    | LoadAllItemsForFilter
    | PutIsLoadingIndicator;
