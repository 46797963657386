import {DatePeriodActions, AddDatePeriodType} from "../actions/datePeriodAction";

const initialState = {
    datePeriod: [],
    dateAdminPeriod: []
}

export const datePeriodReducer = (state = initialState, action: AddDatePeriodType) => {
    switch (action.type) {
        case DatePeriodActions.ADD_DATE_PERIOD:
            return {
                ...state,
                datePeriod: action.payload
            }
        case DatePeriodActions.ADD_ADMIN_DATE_PERIOD:
            return {
                ...state,
                dateAdminPeriod: action.payload
            }
        default:
            return state
    }
}