export const ru = {
    reservation: {
        title: 'Прокат лыжного снаряжения',
        tip: 'Для вибора снаряження выберите тип и нужные даты проката'
    },
    clientDetails: {
        title: 'Условия проката',
        content: [
            {
                title: 'Стоимость',
                content: ['Стоимость проката зависит от количества дней. Дни считаются по фактической дате выдачи и возврата снаряжения.'],
                isList: false
            },
            {
                title: 'Оплата',
                content: ['Наличными за весь срок аренды при получении снаряжения в магазине.', 'Оплата картой онлайн за весь срок аренды  при оформлении заказа.'],
                isList: true
            },
            {
                title: 'Залог',
                content: [
                    'Наличными в магазине при получении снаряжения в магазине',
                    'Блокировка средств на карте (средства будут заблокированы с даты выдачи проката)',
                    'Размер денежного залога просчитывается относительно суммы заказа и будет указан в корзине.'
                ],
                isList: true
            },
            {
                title: 'Скидка',
                content: [
                    'При оформлении проката на комплект: ботинки+лыжи, действует скидка 10%.',
                    'Чем продолжительней сроки аренды, тем меньше получается арендная плата за день.'
                ],
                isList: true
            },
            {
                title: 'Условия проката',
                content: [
                    'Клиент должен аккуратно  обращаться со снаряжением и эксплуатировать его по назначению;',
                    'Клиент должен вернуть оборудование в указанный срок в чистом и исправном состоянии;',
                    'При повреждении взымается штраф;',
                    'Магазин не несет ответственность за жизнь и здоровье клиента;',
                    'При возврате снаряжения досрочно возвращается разница относительно количества дней пользования снаряжением;',
                    'Клиент может отказаться от договора проката в любое время, письменно предупредив о намерении не менее чем за день.',
                ],
                date: 'Во время оформления заказа на сайте, обязательно выбери даты проката',
                isList: true
            },
            {
                title: 'Оформление договорных отношений',
                content: ['При получении товара напрокат, с клиентом заключается договор проката, который подписывается магазином, с одной стороны, и клиентом, с другой стороны. После возврата предмета проката в обоих экземплярах договора делается отметка, свидетельствующая о возврате предмета проката. Сохраняйте все документы по договору проката до его завершения.'],
                isList: false
            },
            {
                title: 'Получение товара',
                content: ['При получении товара клиент должен проверить исправность предоставленного по договору проката товара, а также ознакомиться с правилами эксплуатации'],
                isList: false
            },
            {
                title: 'Возврат',
                content: [
                    'Cнаряжение нужно вернуть в чистом, сухом виде, точно таком же, как при получении и в технически исправном состоянии;',
                    'При повреждении или потери клиент обязан оплатить расходы на его ремонт и приобретение поврежденных частей согласно списку штрафов.'
                ],
                isList: true
            },
            {
                title: 'Штрафы',
                content: [
                    'Просрочка возврата товара — 5% стоимости в сутки.',
                    'Значительное загрязнение — от 50 грн. ',
                    'Повреждение товара — согласно прайсу на ремонтные работы.',
                    'Потеря товара  — согласно оценочной стоимости.'
                ],
                isList: false
            },
            {
                title: 'Изменения и дополнения договора',
                content: [
                    'Любые изменения и дополнения к настоящему договору проката имеют силу только в том случае, если они оформлены в письменном виде и подписаны обеими сторонами.',
                    'Договор проката может быть досрочно расторгнут судом по требованию арендодателя в случаях, когда клиент пользуется имуществом с существенным нарушением условий договора или назначения имущества'
                ],
                isList: true
            }
        ]
    },
    contacts: {
        contactTitle: 'Контакты',
        contactAddressTitle: 'Адресс:',
        contactsAddressInfo: 'Киев, пр-т Победы, 68/1.',
        contactsTimeTitle: 'Время работы:',
        contactsTimeInfo: ['пн-сб 10.00 - 19.00', 'вс 11.00 - 17.00'],
        contactsPhoneTitle: 'Телефон:',
        contactsFPhone: '(044) 456-72-96',
        contactsSPhone: ['+38 (050) 947-60-30', '+38 (050) 330-61-78', '(Viber, WhatsApp)'],
        contactsEmailTitle: 'Email:',
        contactsEmailInfo: 'modena@ukr.net',
        contactsDetails: 'Магазин работает в режиме карантина:',
        contactsDetailsList: ['вход в магазин только в маске', 'одновременно может находится не больше 10 чел']
    },
    header: {
        tabs: ['Условия проката', 'Контакты', 'Спорт магазин', 'О нас', 'Пользовательское соглашение']
    },
    footer: {
        smth: 'Copyright 2006 — 2020 ModenaSport',
        address: 'Киев, проспект Победы 68/1'
    },
    cart: {
        title: 'Оформление заказа',
        itemsTitle: 'Товары',
        amountText: 'Количество',
        priceCardText: ['Цена за', 'день', 'дня', 'дней'],
        priceText: ['цена за', 'день', 'дня', 'дней'],
        zalogText: 'Залог',
        inputZalogText: 'Залог в магазине',
        inputBlockText: ['Блокировка', 'грн. на карте'],
        tipText: 'Средства будут заблокированы с даты выдачи проката',
        depositText: 'Добавить карту',
        numText: 'Номер карты',
        periodText: 'Срок действия',
        inputConfirmText: 'Я ознакомлен с условиями проката',
        inputTipLearnText: 'Ознакомиться с условиями проката',
        inputCardText: 'Выбрать карту',
        writeCardText: 'Введите карту в залоге и выберите ее для оплати',
        contactTitleText: 'Контактные данные',
        rightText: 'Итого',
        periodRightText: 'Даты проката',
        itemsRightText: ['товар', 'товари', 'товарів', 'на сумму'],
        periodRightDate: ['от', 'до'],
        saleText: 'Скидка на комплект:',
        saleSumText: 'Сумма к оплате:',
        orderText: 'Завершить заказ',
        orderTextWithLiqPay: 'Оплатить с помощью LiqPay',
        inputCashText: 'Наличными в магазине',
        inputOnlineText: 'Картой онлайн',
        inputTipConfirmText: 'Подтвердите условия проката',
        addCardText: 'Добавить другую карту',
        writeNumbersText: 'Введите цыфры',
        writeAllNumbersTextErr: 'Введите все цыфры',
        emptyFieldTextErr: 'Поле не может быть пустым',
        writeKirilizaTextErr: 'Используй кирилицу',
        writeEmailTextErr: 'Введить валидный email'
    },
    cardPage: {
        category: 'Категория',
        sizeText: 'Размер',
        amountText: 'Количество',
        priceText: 'Цена за',
        priceForDay: 'день',
        priceForThreeDays: 'дня',
        priceForManyDays: 'дней',
        btnAddText: 'Добавить в корзину',
        describeText: 'Описание',
        characterText: 'Характеристики',
        carouselTitle: 'Похожие товары'
    },
    cartPopup: {
        title: 'Корзина',
        amountText: 'Количество',
        priceText: ['Цена за', 'день', 'дня', 'дней'],
        saleText: 'Скидка на комплект',
        btnContinueShop: 'Продолжить покупки',
        btnConfirmOrder: 'Оформить заказ',
        allText: 'Итого',
        zalogText: 'Залог'
    },
    burger: [
        {
            text: 'Вернуться в магазин',
            link: ''
        },
        {
            text: 'Условия проката',
            link: '#condition'
        },
        {
            text: 'Контакты',
            link: '#contacts'
        },
        {
            text: 'Спорт магазин',
            link: 'https://msport.com.ua/'
        },
        {
            text: 'О нас',
            link: 'https://msport.com.ua/about-us/'
        },
        {
            text: 'Пользовательское соглашение',
            link: '/user-agreements'
        }
    ],
    mobileFilters: {
        title: 'Фильтры',
        btn: ['Показать еще', 'Скрыть'],
        resetText: 'Очистить фильтры'
    },
    userAgreements: {
        title: 'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ',
        mainPhrasesTitle: 'ОСНОВНЫЕ ПОНЯТИЯ',
        phrases: ['Прокат - сервис с одной стороны и заказчик товаров и услуг (далее - Арендатор / Покупатель) с другой стороны, обязуются соблюдать условия настоящего Соглашения.', 'Modena - ФЛП «Фурсенко Андрей Викторович»'],
        points: [
            {
                id: 1,
                title: 'ПРИЕМ И ОБРАБОТКА ЗАКАЗОВ',
                list: [
                    'Заказы от Арендатора/Покупателя принимаются на сайте https://prokat.msport.com.ua в течение 7 дней в неделю и 24 часов в сутки, а также по номерам телефонов: (050) 947-60-30; (044) 456-72-96.',
                    'Когда заказ сделан через корзину, в рабочее время представитель Прокат-Сервис максимально быстро перезвонит Арендатору/Покупателю для подтверждения оформленного заказа и уточнения всех деталей. Когда заказ сделан в нерабочее время, выходные или праздничные дни, менеджер Прокат-Сервис свяжется с Арендатором/Покупателем в следующий рабочий день или в ближайшее свободное время.',
                    'Когда заказ размещен, Арендатор/Покупатель получает уведомление на электронный адрес указанный при оформлении заказа о том, что его заказ принят. С Арендатором/Покупателем свяжется представитель Прокат-Сервис для уточнения деталей на заказ: актуальности цены, уточнения условий оплаты и доставки заказанного товара, проверки наличия заказываемых товаров на складе.',
                    'Не до конца оформленным считается заказ, если Арендатор/Покупатель не прошел этап подтверждения заказа с представителем Прокат-Сервис по телефону (Покупатель не отвечает на звонок, находится вне зоны действия сети)',
                ]
            },
            {
                id: 2,
                title: 'ДОСТАВКА И ОПЛАТА ТОВАРА',
                list: [
                    'Покупателю регламентируются условия оплаты и доставки в разделе Прокат.',
                    'Всегда существует возможность возникновения ситуации, при которой на доставку требуется больше рабочих дней, чем обычно (отсутствие свободных машин для доставки заказа, задержки, вызванные работой служб доставки, и др.) или доставка будет вообще временно невозможна. При этом Прокат-Сервис берет на себя никаких обязательств по возмещению любого ущерба Арендатора/Покупателя, связанных с несвоевременной поставкой заказа. Если Арендатор / Покупатель не устраивает сроки поставки, он имеет право отказаться от заказа, перезвонив на телефоны указанные в разделе «Контакты».',
                ]
            },
            {
                id: 3,
                title: 'ПРОВЕРКА ТОВАРА',
                list: [
                    'При получении заказа в офисе Прокат-сервис или со склада компании-перевозчика, Арендатор/Покупатель должен проверить качество и количество получаемого товара. Претензии о некомплектности или отсутствии какого-либо товара после того, как Покупатель получил заказ, не рассматриваются и не принимаются.'
                ]
            },
            {
                id: 4,
                title: 'СРОКИ ГАРАНТИИ',
                list: [
                    'Каждый производитель предоставляет на свой товар разные сроки гарантии, Арендатор/Покупатель при оформлении заказа должен уточнять у представителя Прокат-Сервис гарантийные сроки на товары во избежание недоразумений.',
                    'Гарантийный срок установленный производителем начинается со дня получения товара Арендатором/Покупателем из офиса Прокат Сервис или со дня отправки товара Арендатору/Покупателю через компанию-перевозчика.'
                ]
            },
            {
                id: 5,
                title: 'ОПИСАНИЕ ТОВАРОВ',
                list: [
                    'Все материалы предоставлены на сайте https://prokat.msport.com.ua, размещены в ознакомительных целях и могут отличаться от действительности, включая описания товаров, их характеристики, комплектацию, а также сами фотографии. Администрация сайта https://prokat.msport.com.ua, не несет ответственности за неточности, допущенные в описаниях и характеристиках товаров.'
                ]
            },
            {
                id: 6,
                title: 'ОБЩИЕ УСЛОВИЯ',
                list: [
                    'Использование материалов и услуг Сайта регулируется нормами действующего законодательства Украины.',
                    'Настоящее Соглашение является публичной офертой. Получая доступ к материалам Сайта Арендатор / Покупатель / Пользователь считается присоединившимся к настоящему Соглашению.',
                    'Администрация Сайта имеет право в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу по истечении 3 (трех) дней с момента размещения новой версии Соглашения на сайте. При несогласии Арендатора/Покупателя/Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта.',
                ]
            },
            {
                id: 7,
                title: 'РЕГИСТРАЦИЯ НА САЙТЕ',
                list: [
                    'Регистрируясь на Сайте, вы соглашаетесь предоставить достоверную и точную информацию о себе и своих контактных данных, а также принимаете условия соглашения.'
                ]
            },
            {
                id: 8,
                title: 'ПЕРСОНАЛЬНЫЕ ДАННЫЕ',
                list: [
                    'Арендатор / Покупатель / Пользователь, оформляя заказ на Сайте, дает свое согласие Прокат-Сервис на обработку и сбор, а также накопление, хранение, использование и распространение указанных им данных, а именно: ФИО, электронная почта, телефон, адрес, с целью обеспечения реализации отношений купли-продажи, отношений в области защиты прав потребителей, в сфере рекламы и маркетинговых исследований.',
                    'Сбором персональных данных есть информация, непосредственно и добровольно предоставленная Арендатором / Покупателем / Пользователем на нашем сайте https://prokat.msport.com.ua.'
                ]
            }
        ]
    },
    cartForm: [
        {text: 'Имя', name: 'name', placeholder: 'Введите имя'},
        {text: 'Фамилия', name: 'secondName', placeholder: 'Введите фамилию'},
        {text: 'Email', name: 'email', placeholder: 'Введите почту'},
        {text: 'Номер телефона', name: 'phone', placeholder: 'Введите номер телефона'}
    ]
}

export const ua = {
    reservation: {
        title: 'Прокат лижного спорядження',
        tip: 'Для вибора спорядження виберіть тип і потрібні дати прокату'
    },
    clientDetails: {
        title: 'Умови прокату',
        content: [
            {
                title: 'Вартість',
                content: ['Вартість прокату залежить від кількості днів. Дні рахуються за фактичною датою видачі та повернення спорядження.'],
                isList: false
            },
            {
                title: 'Оплата',
                content: ['Готівкою за весь термін оренди при отриманні спорядження в магазині. ', ' Оплата картою онлайн за весь термін оренди при оформленні замовлення.'],
                isList: true
            },
            {
                title: 'Застава',
                content: [
                    'Готівкою в магазині при отриманні спорядження в магазині',
                    'Блокування коштів на мапі (кошти будуть заблоковані з дати видачі прокату)',
                    'Розмір грошової застави прораховується щодо суми замовлення і буде вказано в кошику.'
                ],
                isList: true
            },
            {
                title: 'Знижка',
                content: [
                    'При оформленні прокату на комплект: черевики + лижі, діє знижка 10%.',
                    'Чим триваліший термін оренди, тим менше виходить орендна плата за день.'
                ],
                isList: true
            },
            {
                title: 'Умови прокату',
                content: [
                    'Клієнт повинен акуратно поводитися зі спорядженням і експлуатувати його за призначенням;',
                    'Клієнт повинен повернути обладнання в зазначений термін в чистому і справному стані;',
                    'При пошкодженні стягується штраф;',
                    'Магазин не несе відповідальність за життя і здоров\'я клієнта;',
                    'При поверненні спорядження достроково повертається різниця щодо кількості днів користування спорядженням;',
                    'Клієнт може відмовитися від договору прокату в будь-який час, письмово попередивши про намір не менше ніж за день.'
                ],
                date: 'Під час оформлення заказу на сайті, обов`язково обери дати прокату',
                isList: true
            },
            {
                title: 'Оформлення договірних відносин',
                content: ['При отриманні товару напрокат, з клієнтом укладається договір прокату, який підписується магазином, з одного боку, і клієнтом, з іншого боку. Після повернення предмета прокату в обох примірниках договору робиться відмітка, яка свідчить про повернення предмета прокату. Зберігайте всі документи за договором прокату до його завершення.'],
                isList: false
            },
            {
                title: 'Отримання товару',
                content: ['При отриманні товару покупець зобов\'язаний перевірити справність наданого за договором прокату товару, а також ознайомитися з правилами експлуатації'],
                isList: false
            },
            {
                title: 'Повернення',
                content: [
                    'Зпорядження потрібно повернути в чистому, сухому вигляді, точно такому ж, як при отриманні і в технічно справному стані;',
                    'При пошкодженні або втрати клієнт зобов\'язаний оплатити витрати на його ремонт і придбання пошкоджених частин згідно зі списком штрафів.'
                ],
                isList: true
            },
            {
                title: 'Штрафи',
                content: [
                    'Прострочення повернення товару - 5% вартості на добу.',
                    'Значне забруднення - від 50 грн.',
                    'Пошкодження товару - згідно прайсу на ремонтні роботи.',
                    'Втрата товару - згідно ринкової вартості.'
                ],
                isList: false
            },
            {
                title: 'Зміни і доповнення договору',
                content: [
                    'Будь-які зміни і доповнення до цього договору прокату мають силу тільки в тому випадку, якщо вони оформлені в письмовому вигляді і підписані обома сторонами.',
                    'Договір прокату може бути достроково розірваний судом на вимогу орендодавця у випадках, коли клієнт користується майном з істотним порушенням умов договору або призначення майна'
                ],
                isList: true
            }
        ]
    },
    contacts: {
        contactTitle: 'Контакти',
        contactAddressTitle: 'Адреса:',
        contactsAddressInfo: 'Київ, пр-т Перемоги, 68/1.',
        contactsTimeTitle: 'Години работи:',
        contactsTimeInfo: ['пн-сб 10.00 - 19.00', 'нд 11.00 - 17.00'],
        contactsPhoneTitle: 'Телефон:',
        contactsFPhone: '(044) 456-72-96',
        contactsSPhone: ['+38 (050) 947-60-30', '+38 (050) 330-61-78', '(Viber, WhatsApp)'],
        contactsEmailTitle: 'Email:',
        contactsEmailInfo: 'modena@ukr.net',
        contactsDetails: 'Магазин працює у режимі карантина:',
        contactsDetailsList: ['вхід до магазину тільки у масці', 'одночасно може перебувати не більше 10 чол']
    },
    header: {
        tabs: ['Умови прокату', 'Контакти', 'Спорт магазин', 'Про нас', 'Користувацька угода']
    },
    footer: {
        smth: 'Copyright 2006 — 2020 ModenaSport',
        address: 'Київ, проспект Перемоги 68/1'
    },
    cart: {
        title: 'Оформлення замовлення',
        itemsTitle: 'Товари',
        amountText: 'Кількість',
        priceText: ['ціна за', 'день', 'дні', 'днів'],
        priceCardText: ['Ціна за', 'день', 'дні', 'днів'],
        zalogText: 'Застава',
        inputZalogText: 'Застава в магазині',
        inputBlockText: ['Блокування', 'грн. на карті'],
        tipText: 'Кошти будуть заблоковані з дати видачі проката',
        depositText: 'Додати карту',
        numText: 'Номер карти',
        periodText: 'Термін дії',
        inputConfirmText: 'Я ознайомлений з умовами прокату',
        inputTipLearnText: 'Ознайомтеся з умовами прокату',
        inputCardText: 'Обрати карту',
        writeCardText: 'Введіть карту у заставі і оберіть її для оплати',
        contactTitleText: 'Контактні дані',
        rightText: 'Загалом',
        periodRightText: 'Дати прокату',
        itemsRightText: ['товар', 'товари', 'товарів', 'на сумму'],
        periodRightDate: ['з', 'до'],
        saleText: 'Знижка на комплект:',
        saleSumText: 'Сума до сплати:',
        orderText: 'Завершити замовлення',
        orderTextWithLiqPay: 'Оплатити за допомогою LiqPay',
        inputCashText: 'Готівкою в магазині',
        inputOnlineText: 'Картою онлайн',
        inputTipConfirmText: 'Підтвердіть умови проката',
        addCardText: 'Додати іншу карту',
        writeNumbersText: 'Введіть цифри',
        writeAllNumbersTextErr: 'Введіть усі цифри',
        emptyFieldTextErr: 'Поле не може бути пустим',
        writeKirilizaTextErr: 'Використовуй кирилицю',
        writeEmailTextErr: 'Введіть валідний email'
    },
    cardPage: {
        category: 'Категорія',
        sizeText: 'Розмір',
        amountText: 'Кількість',
        priceText: 'Ціна за',
        priceForDay: 'день',
        priceForThreeDays: 'дні',
        priceForManyDays: 'днів',
        btnAddText: 'Додати у кошик',
        describeText: 'Опис',
        characterText: 'Характеристики',
        carouselTitle: 'Схожі товари'
    },
    cartPopup: {
        title: 'Кошик',
        amountText: 'Кількість',
        priceText: ['Ціна за', 'день', 'дні', 'днів'],
        saleText: 'Знижка на комплект',
        btnContinueShop: 'Продовжити покупки',
        btnConfirmOrder: 'Оформити замовлення',
        allText: 'Заголом',
        zalogText: 'Застава'
    },
    burger: [
        {
            text: 'Повернутися до магазина',
            link: ''
        },
        {
            text: 'Умови прокату',
            link: '#condition'
        },
        {
            text: 'Контакти',
            link: '#contacts'
        },
        {
            text: 'Спорт магазин',
            link: 'https://msport.com.ua/'
        },
        {
            text: 'Про нас',
            link: 'https://msport.com.ua/about-us/'
        },
        {
            text: 'Користувацька угода',
            link: '/user-agreements'
        }
    ],
    mobileFilters: {
        title: 'Фільтри',
        btn: ['Показати ще', 'Згорнути'],
        resetText: 'Очистити фільтри'
    },
    userAgreements: {
        title: 'КОРИСТУВАЦЬКА УГОДА',
        mainPhrasesTitle: 'ОСНОВНІ ПОНЯТТЯ',
        phrases: ['Прокат - cервіс з одного боку і замовник товарів та послуг (далі - Орендар / Покупець) з іншого боку, зобов\'язуються дотримуватися умов цієї Угоди.', 'Modena - ФОП «Фурсенко Андрій Вікторович»'],
        points: [
            {
                id: 1,
                title: 'ПРИЙОМ І ОБРОБКА ЗАМОВЛЕНЬ',
                list: [
                    'Замовлення від Орендаря/Покупця приймаються на сайті  https://prokat.msport.com.ua  протягом 7 днів на тиждень і 24 годин на добу, а також за номерами телефонів: (050) 947-60-30; (044)456-72-96.',
                    'Коли замовлення зроблено через корзину, в робочий час, представник Прокат-Сервіс максимально швидко передзвонить Орендарю / Покупцеві для підтвердження оформленого замовлення і уточнення всіх деталей. Коли замовлення зроблено в неробочий час, вихідні або святкові дні, менеджер Прокат-Сервіс зв\'яжеться з Орендарем / Покупцем в наступний робочий день або найближчим вільний час.',
                    'Коли замовлення розміщене, Орендар / Покупець отримує повідомлення на електронну адресу вказану при оформленні замовлення про те, що його замовлення прийняте. З Орендарем / Покупцем зв\'яжеться представник Прокат-Сервіс для уточнення деталей на замовлення: актуальності ціни, уточнення умов оплати і доставки замовленого товару, перевірки наявності товарів що замовляються на складі.',
                    'Не до кінця оформленим вважається замовлення, якщо Орендар / Покупець не пройшов етап підтвердження замовлення з представником Прокат-Сервіс по телефону (Покупець не відповідає на дзвінок, знаходиться поза зоною дії мережі)',
                ]
            },
            {
                id: 2,
                title: 'ДОСТАВКА І ОПЛАТА ТОВАРУ',
                list: [
                    'Покупцеві регламентуються умови оплати і доставки в розділі Прокат.',
                    'Завжди існує можливість виникнення ситуації, при якій на доставку потрібно більше робочих днів, ніж зазвичай (відсутність вільних машин для доставки замовлення, затримки, викликані роботою служб доставки, і ін.), або доставка буде взагалі тимчасово неможлива. При цьому Прокат-Сервіс бере на себе ніяких зобов\'язань по відшкодуванню будь-яких збитків Орендаря / Покупця, пов\'язаних з несвоєчасною поставкою замовлення. Якщо Орендаря / Покупця не влаштовують терміни поставки, він має право відмовитися від замовлення, передзвонивши на телефони вказані в розділі «Контакти».',
                ]
            },
            {
                id: 3,
                title: 'ПЕРЕВІРКА ТОВАРУ',
                list: [
                    'При отриманні замовлення в офісі Прокат-Сервіс або зі складу компанії-перевізника, Орендар / Покупець повинен перевірити якість і кількість одержуваного товару. Претензії про некомплектність або відсутності будь-якого товару після того, як Покупець отримав замовлення, не розглядаються і не приймаються.'
                ]
            },
            {
                id: 4,
                title: 'ТЕРМІНИ ГАРАНТІЇ',
                list: [
                    'Кожен виробник надає на свій товар різні терміни гарантії, Орендар / Покупець при оформленні замовлення повинен уточнювати у представника Прокат-Сервіс гарантійні терміни на товари з метою уникнення непорозумінь.',
                    'Гарантійний термін встановлений виробником починається з дня отримання товару Орендарем / Покупцем з офісу Прокат Сервіс або з дня відправки товару Орендарю / Покупцеві через компанію-перевізника.',
                ]
            },
            {
                id: 5,
                title: 'ОПИСУ ТОВАРІВ',
                list: [
                    'Всі матеріали надані на сайті https://prokat.msport.com.ua , розміщені з ознайомчою метою і можуть відрізняться від дійсності, включаючи описи товарів, їх характеристики, комплектацію, а також самі фотографії. Адміністрація сайту https://prokat.msport.com.ua  ,не несе відповідальності за неточності, допущені в описах і характеристиках товарів.'
                ]
            },
            {
                id: 6,
                title: 'ЗАГАЛЬНІ УМОВИ',
                list: [
                    'Використання матеріалів і сервісів Сайту регулюється нормами чинного законодавства України.',
                    'Ця Угода є публічною офертою. Отримуючи доступ до матеріалів Сайту Орендар / Покупець / Користувач вважається приєднавшимся до цієї Угоди.',
                    'Адміністрація Сайту має право в будь-який час в односторонньому порядку змінювати умови цієї Угоди. Такі зміни вступають в силу після закінчення 3 (трьох) днів з моменту розміщення нової версії Угоди на сайті. При незгоді Орендаря / Покупця / Користувача з внесеними змінами він зобов\'язаний відмовитися від доступу до Сайту, припинити використання матеріалів і сервісів Сайту. ',
                ]
            },
            {
                id: 7,
                title: 'РЕЄСТРАЦІЯ НА САЙТІ',
                list: [
                    'Реєструючись на Сайті, ви погоджуєтеся надати достовірну і точну інформацію про себе і свої контактні дані, а також приймаєте умови угоди.'
                ]
            },
            {
                id: 8,
                title: 'ПЕРСОНАЛЬНІ ДАНІ',
                list: [
                    'Орендар / Покупець / Користувач, оформляючи замовлення на Сайті, дає свою згоду Прокат-Сервіс на обробку і збір, а також накопичення, зберігання, використання і поширення зазначених їм даних, а саме: ПІБ, електронна пошта, телефон, адреса, з метою забезпечення реалізації відносин купівлі-продажу, відносин у сфері захисту прав споживачів, у сфері реклами і маркетингових досліджень.',
                    'Збором персональних даних є інформація, безпосередньо і добровільно надана Орендарем / Покупцем / Користувачем на нашому сайті https://prokat.msport.com.ua.'
                ]
            }
        ]
    },
    cartForm: [
        {text: 'Ім\'я', name: 'name', placeholder: 'Введіть ім\'я'},
        {text: 'Прізвище', name: 'secondName', placeholder: 'Введіть прізвище'},
        {text: 'Пошта', name: 'email', placeholder: 'Введіть пошту'},
        {text: 'Номер телефона', name: 'phone', placeholder: 'Введіть номер телефона'}
    ]
}
