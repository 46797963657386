import {RouteType, RouteActions} from "../actions/routerActions";
const initialState: [] = [];

export const routerReducer = (state = initialState, action: RouteType) => {
    switch (action.type) {
        case RouteActions.PUT_ROUTE:
            return action.payload
        default:
            return state
    }
}