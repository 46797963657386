import { notification } from "antd";

export const autoCountPriceWithDay = (n: number, pr: number, k: number = 1.5) => {
    let day1 = pr * k;
    let day2 = day1 + (pr * (k - 0.1));
    let day3 = day2 + (pr * (k - 0.2))
    let day4 = day3 + (pr * (k - 0.3))
    let day5 = day4 + (pr * (k - 0.4))
    let day6 = day5 + (pr * (k - 0.5))
    let day7 = day6 + (pr * (k - 0.6))
    let day8 = day7 + (pr * (k - 0.6))
    let day9 = day8 + (pr * (k - 0.6))
    let day10 = day9 + (pr * (k - 0.7))
    let day11 = day10 + (pr * (k - 0.8))
    let day12 = day11 + (pr * (k - 0.9))
    let day13 = day12 + (pr * (k - 1))
    let day14 = day13 + (pr * (k - 1))

    switch (n) {
        case 0:
            return day1
        case 1:
            return day1
        case 2:
            return day2
        case 3:
            return day3
        case 4:
            return day4
        case 5:
            return day5
        case 6:
            return day6
        case 7:
            return day7
        case 8:
            return day8
        case 9:
            return day9
        case 10:
            return day10
        case 11:
            return day11
        case 12:
            return day12
        case 13:
            return day13
        case 14:
            return day14
        default:
            return day14
    }
};

export const fakeApiCall = (cb: Function, time: number) => {
    cb();
    return new Promise(resolve => {
        setTimeout(() => resolve(), time);
    });
};

export const getNotification = (type: string, message: string, description?: string) => {
    // @ts-ignore
    notification[type]({
        message: message,
        description: description,
        duration: 4,
    });
};
