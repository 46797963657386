import { Card } from "../constants";

export enum CartActions {
    ADD_CART_ITEM = "ADD_CART_ITEM",
    DELETE_CART_ITEM = "DELETE_CART_ITEM",
    CLEAR_CART = "CLEAR_CART",
}

interface AddCartItem {
    type: typeof CartActions.ADD_CART_ITEM,
    payload: Card
}

interface DeleteCartItem {
    type: typeof CartActions.DELETE_CART_ITEM,
    payload: any
}

interface ClearCart {
    type: typeof CartActions.CLEAR_CART
}

export type CartType = AddCartItem | DeleteCartItem | ClearCart
