import {all, call, put, takeEvery} from 'redux-saga/effects';
import {DataActions} from "../actions/dataAction";
import {
    putAdminData,
    putAdminDataOrders,
    putAdminItemFromStart,
    putAllItemsForFilters,
    putData,
    putFromStart,
    putItemForDiscount,
    putOrderFromStart,
    putRoute,
    putSameItems,
    setIsLoadingIndicator
} from '../actions'
import {RouteActions} from "../actions/routerActions";
import {AdminDataActions} from "../actions/adminDataAction";
import _ from "lodash";
import axios from "axios";

// data

async function loadData(params: {
    startRent: number | undefined,
    endRent: number | undefined,
    type: string[],
    size: number,
    currentPage: number,
    isAllItems: boolean,
    category: { categories: string[], heights: string[], genders: string[] },
    sort: string,
    lang: string
}) {
    const {
        startRent,
        endRent,
        type,
        size,
        currentPage,
        isAllItems,
        category: {categories, genders, heights},
        sort,
        lang
    } = params;

    const changeCategoryUkrLangToRus = (category: string, lang: string) => {
        if (lang === 'ua') {
            if (category === 'Стандарт') {
                return 'Стандарт'
            } else {
                return 'Премиум'
            }
        } else {
            if (category === 'Стандарт') {
                return 'Стандарт'
            } else {
                return 'Премиум'
            }
        }
    }

    const changeGenderUkrLangToRus = (category: string, lang: string) => {
        if (lang === 'ua') {
            return category === 'Чоловічий' ? 'Мужской' : category === 'Жіночий' ? 'Женский' : 'Детский'
        } else {
            return category
        }
    }

    const s = sort ? `&properties=${sort.split(',')[0]}&direction=${sort.split(',')[1].toUpperCase()}` : ''
    const t = type.length >= 1 ? type[0] === 'выбрать все' || type[0] === 'вибрати все' ? '' : `&type=${type[0]}` : '';
    const g = genders.length >= 1 ? `&gender=${changeGenderUkrLangToRus(genders[0], lang)}` : '';
    const h = heights.length >= 1 ? `&size=${heights[0]}` : '';
    const c = categories.length >= 1 ? `&category=${changeCategoryUkrLangToRus(categories[0], lang)}` : '';

    const sortUrl = `/api/v1/item?pageSize=${size}&pageNumber=${currentPage}&startRent=${startRent}&endRent=${endRent}${s}${c}${h}${g}${t}`;

    const url = `/api/v1/item?pageSize=${size}&pageNumber=${currentPage}&startRent=${startRent}&endRent=${endRent}`;

    const definedUrl = isAllItems && [...categories, ...genders, ...heights].length === 0 ? url : sortUrl;

    const data: any = await axios.get(definedUrl);

    return {
        data: data.data,
        currentPage: currentPage,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
    }
}

function* loadDataWorker(action: any) {
    const {startRent, endRent, type, size, currentPage, isAllItems, category, sort, lang} = action.payload;

    yield put(setIsLoadingIndicator());

    const data = yield call(loadData, {startRent, endRent, type, size, currentPage, isAllItems, category, sort, lang});

    yield put(putData(data));
}

function* loadDataWatcher() {
    yield takeEvery(DataActions.LOAD_DATA, loadDataWorker)
}

async function loadItemForDiscount(params: any) {
    const {startRent, endRent, type} = params;

    const res = await axios.get(`/api/v1/item?startRent=${startRent}&endRent=${endRent}&type=${type}&pageSize=10&pageNumber=0`)
    return _.sample(res.data);
}

function* loadItemForDiscountWorker(action: any) {
    const {startRent, endRent, type} = action.payload;
    const item = yield call(loadItemForDiscount, {startRent, endRent, type});

    yield put(putItemForDiscount(item));
}

function* loadItemForDiscountWatcher() {
    yield takeEvery(DataActions.LOAD_ITEM_FOR_DISCOUNT, loadItemForDiscountWorker)
}


//load all items for filter

async function loadAllItemsForFilter() {
    const heights: any = await axios.get('/api/v1/item/count/size')
    const genders: any = await axios.get('/api/v1/item/count/gender')
    const categories: any = await axios.get('/api/v1/item/count/category')

    const amountOfStandards = categories.data['Стандарт'] || 0;
    const amountOfPremiums = categories.data['Премиум'] || 0;
    const amountOfWSizes = genders.data['Женский'] || 0;
    const amountOfMSizes = genders.data['Мужской'] || 0;
    const amountOfCSizes = genders.data['Детский'] || 0;

    const amountOfSizes = Object.entries(heights.data).map((item: any) => ({name: item[0], count: item[1]}));

    return {
        amountOfStandards,
        amountOfPremiums,
        allSizes: Object.keys(heights.data),
        amountOfWSizes,
        amountOfMSizes,
        amountOfCSizes,
        amountOfSizes
    };
}

function* loadAllItemsForFilterWorker() {
    const item = yield call(loadAllItemsForFilter);

    yield put(putAllItemsForFilters(item));
}

function* loadAllItemsForFilterWatcher() {
    yield takeEvery(DataActions.LOAD_ALL_ITEMS_FOR_FILTER, loadAllItemsForFilterWorker)
}

// load same items for type

async function loadSameItems(params: any) {
    const {startRent, endRent, type} = params;

    const res = await axios.get(`/api/v1/item?startRent=${startRent}&endRent=${endRent}&type=${type}&pageSize=12&pageNumber=0`)

    return res.data;
}

function* loadSameItemsWorker(action: any) {
    const {startRent, endRent, type} = action.payload;

    const item = yield call(loadSameItems, {startRent, endRent, type});
    yield put(putSameItems(item));
}

function* loadSameItemsWatcher() {
    yield takeEvery(DataActions.LOAD_SAME_ITEMS, loadSameItemsWorker)
}

//load item from start

async function loadItemFromStart(params: any) {
    const {id} = params;
    return await axios.get(`/api/v1/item/${id}`)
}

function* loadItemFromStartWorker(action: any) {
    const {id} = action.payload
    const item = yield call(loadItemFromStart, {id});

    yield put(putFromStart(item));
}

function* loadItemFromStartWatcher() {
    yield takeEvery(DataActions.LOAD_ITEM_FROM_START, loadItemFromStartWorker)
}

//load admin order from start

async function loadOrderFromStart(params: { orderId: string}) {
    const {orderId} = params;
    return await axios.get(`/api/v1/order/${orderId}`);
}

function* loadOrderFromStartWorker(action: any) {
    const {orderId} = action.payload;

    const item = yield call(loadOrderFromStart, {orderId});
    yield put(putOrderFromStart(item));
}

function* loadOrderFromStartWatcher() {
    yield takeEvery(AdminDataActions.LOAD_ORDER_FROM_START, loadOrderFromStartWorker)
}

//load admin item from start

async function loadAdminItemFromStart(params: any) {
    return await axios.get(`/api/v1/item/${params.id}`)
}

function* loadAdminItemFromStartWorker(action: any) {
    const item = yield call(loadAdminItemFromStart, {id: action.payload.id});

    yield put(putAdminItemFromStart(item));
}

function* loadAdminItemFromStartWatcher() {
    yield takeEvery(AdminDataActions.LOAD_ADMIN_ITEM_FROM_START, loadAdminItemFromStartWorker)
}

// router

function* fetchRouteWorker(action: any) {
    yield put(putRoute(action.payload));
}

function* fetchRouteWatcher() {
    yield takeEvery(RouteActions.LOAD_ROUTE, fetchRouteWorker)
}

//adminData

async function loadAdminData(params: { startRent: string, endRent: string }) {
    const {startRent, endRent} = params;

    return await axios.get(`api/v1/item?startRent=${startRent}&endRent=${endRent}&pageSize=9&pageNumber=0`)
}

function* loadAdminDataWorker(action: any) {
    const {startRent, endRent} = action.payload;
    const data = yield call(loadAdminData, {startRent, endRent});

    yield put(putAdminData(data));
}

function* loadAdminDataWatcher() {
    yield takeEvery(AdminDataActions.LOAD_ADMIN_DATA, loadAdminDataWorker)
}

// admin orders
async function loadAdminDataOrders(params: { startRent: string, endRent: string, currentPage: string }) {
    const {startRent, endRent, currentPage} = params;

    const res: any = await axios.get(`/api/v1/order?startTime=${startRent}&endTime=${endRent}&pageSize=9&pageNumber=${currentPage}&properties=amount&direction=DESC`);

    return {
        data: res.data,
        currentPage: currentPage,
        totalPages: res.totalPages,
        totalElements: res.totalElements,
    };
}

function* loadAdminDataOrdersWorker(action: any) {
    const {startRent, endRent, currentPage} = action.payload;
    const data = yield call(loadAdminDataOrders, {startRent, endRent, currentPage});

    yield put(putAdminDataOrders(data));
}

function* loadAdminDataOrdersWatcher() {
    yield takeEvery(AdminDataActions.LOAD_ADMIN_DATA_ORDERS, loadAdminDataOrdersWorker)
}

// all
export default function* rootSaga() {
    yield all([
        loadDataWatcher(),
        loadItemForDiscountWatcher(),
        fetchRouteWatcher(),
        loadAdminDataWatcher(),
        loadAdminDataOrdersWatcher(),
        loadSameItemsWatcher(),
        loadItemFromStartWatcher(),
        loadAllItemsForFilterWatcher(),
        loadOrderFromStartWatcher(),
        loadAdminItemFromStartWatcher()
    ])
}
