import React, {useEffect, useRef, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";

import EquipmentDropdown from "../../components/Dropdown/equipmentDropdown";
import Button from "../../components/Button";
import DataPicker from "../../components/DataPicker/index";

import {addDatePeriod, addType, deleteType} from "../../redux/actions";
import {ICard} from "../../components/Card";

import './style.scss';

const ToolsBoard: React.FC<{ toolsBoardLang: any }> = ({toolsBoardLang}) => {
    const dispatch = useDispatch();

    // REDUX

    const types = useSelector((state: RootStateOrAny) => state.filters.types);
    const innerWidth = useSelector((state: RootStateOrAny) => state.width.width);
    // const asideFilters = useSelector((state: RootStateOrAny) => state.filters.asideFilters);
    const lang = useSelector((state: RootStateOrAny) => state.lang.lang);
    const data: ICard[] = useSelector((state: RootStateOrAny) => state.data.data);

    // STATE

    const [isOpenEquipDD, setOpenEquipDD] = useState(false);
    //auto close DD
    const wrapperRef = useRef(null);

    // SessionStorage

    const datePeriodSS = JSON.parse(sessionStorage.getItem('dataPeriod') as string);

    // FUNCTIONS

    const handleOpenEquipDD = () => setOpenEquipDD(!isOpenEquipDD);

    const handleChooseItem = (elem: string, event: any) => {
        event.checked ?
            dispatch(addType(elem.toLowerCase())) :
            dispatch(deleteType(elem.toLowerCase()))
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const datePeriodSS = JSON.parse(sessionStorage.getItem('dataPeriod') as string);

        if(types.length > 0 && datePeriodSS) {
            datePeriodSS && typeof datePeriodSS[1] === 'number' && dispatch(addDatePeriod(datePeriodSS));
        }
    }

    const handleClickOutside = (event: any) => {
        // @ts-ignore
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            return setOpenEquipDD(false);
        }
    };

    // LIFECYCLES

    useEffect(() => {
        const type = sessionStorage.getItem('type');

        type && dispatch(addType(type));

        if(data && datePeriodSS) {
            typeof datePeriodSS[1] === 'number' && dispatch(addDatePeriod(datePeriodSS));
        }
    }, []);


    useEffect(() => {
         if (types.length === 1) {
            if (lang === "UA") {
                types[0] === 'выбрать все' ? dispatch(addType('вибрати все')) :
                    types[0] === 'лыжи' ? dispatch(addType('лижі')) :
                    types[0] === 'ботинки' ? dispatch(addType('черевики')) :
                        types[0] === 'аксессуары' ? dispatch(addType('аксесуари')) : dispatch(addType(types[0]))
            } else {
                types[0] === 'вибрати все' ? dispatch(addType('выбрать все')) :
                    types[0] === 'лижі' ? dispatch(addType('лыжи')) :
                    types[0] === 'черевики' ? dispatch(addType('ботинки')) :
                        types[0] === 'аксесуари' ? dispatch(addType('аксессуары')) : dispatch(addType(types[0]))
            }
        }
    }, [lang])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, [wrapperRef]);

    return (
        <div className='toolsBoard'>
            <form className="toolsBoard_inner" onSubmit={handleSubmit}>
                <div className='toolsBoard_inner_drop' ref={wrapperRef}>
                    <div className="toolsBoard_inner_drop_main" onClick={handleOpenEquipDD}>
                        <p className="toolsBoard_inner_drop_text">{
                            types.length === 0 ? toolsBoardLang.equipmentText :
                                types.join(',').length > 25 ?
                                    `${types.map((e: string) => e[0].toUpperCase() + e.slice(1)).join(', ').slice(0, 25)}...` :
                                    types.map((e: string) => e[0].toUpperCase() + e.slice(1)).join(', ')
                        }</p>
                        <img src="/images/arrow.svg" alt="arrow"
                             style={{transform: isOpenEquipDD ? 'rotate(180deg)' : 'rotate(0deg)'}}/>
                    </div>
                    {isOpenEquipDD &&
                        <EquipmentDropdown
                            handleChooseItem={handleChooseItem}
                            types={types}
                            textTypes={toolsBoardLang.textTypes}
                            handleOpenEquipDD={handleOpenEquipDD}
                        />}
                </div>
                <DataPicker innerWidth={+innerWidth}/>
                <Button textBtn={toolsBoardLang.textBtn} type={'submit'}/>
            </form>
        </div>
    );
};

export default ToolsBoard;
