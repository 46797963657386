import React from 'react';

import './style.scss';

const Footer: React.FC<{smth: string, address: string} > = ({smth, address})=> {
    return (
        <div className='footer'>
            <div className="footer_inner container">
                <div className="footer_inner_left">
                    <div className="footer_inscription">
                        <p>{smth}</p>
                        <p>{address}</p>
                    </div>
                    <div className="footer_inner_left_images">
                        <img src="/images/liqpay-logo.svg" alt="liqpay"/>
                        <img src="/images/mastercard-logo.svg" alt="mastercard"/>
                        <img src="/images/visa-logo.svg" alt="visa"/>
                    </div>
                </div>
                <div className="footer_phones">
                    <p>тел. (044) 456-72-96</p>
                    <p>тел. (050) 947-60-30</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
