export enum DatePeriodActions {
    ADD_DATE_PERIOD = "ADD_DATE_PERIOD",
    ADD_ADMIN_DATE_PERIOD = "ADD_ADMIN_DATE_PERIOD"
}

interface AddDatePeriod {
    type: typeof DatePeriodActions.ADD_DATE_PERIOD,
    payload: string[]
}

interface AddAdminDatePeriod {
    type: typeof DatePeriodActions.ADD_ADMIN_DATE_PERIOD,
    payload: string[]
}

export type AddDatePeriodType = AddDatePeriod | AddAdminDatePeriod;