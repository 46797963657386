import React from 'react';
import Checkbox from "../Checkbox/index";

interface IEquipmentDropdown {
    types: string[],
    handleChooseItem: (elem: string, event: any) => void,
    handleOpenEquipDD: () => void,
    textTypes: string[]
}

const EquipmentDropdown: React.FC<IEquipmentDropdown> = ({handleChooseItem, types, handleOpenEquipDD, textTypes}) => {
    return (
        <div className='equipDrop'>
            <div className="equipDrop_inner">
                {textTypes.map((d: string) => {
                    return <Checkbox
                    key = {d}
                    text = {d}
                    inputName = {d}
                    handleChooseItem = {handleChooseItem}
                    isChecked = {types.includes(d.toLowerCase())}
                    handleOpenEquipDD={handleOpenEquipDD}
                    />
                })
                }
            </div>
        </div>
    );
};

export default EquipmentDropdown;
