import {Card} from "../constants";

export enum AdminDataActions {
    LOAD_ADMIN_DATA = "LOAD_ADMIN_DATA",
    PUT_ADMIN_DATA = "PUT_ADMIN_DATA",
    CHOOSE_ADMIN_ITEM = "CHOOSE_ADMIN_ITEM",
    LOAD_ADMIN_DATA_ORDERS = "LOAD_ADMIN_DATA_ORDERS",
    PUT_ADMIN_DATA_ORDERS = "PUT_ADMIN_DATA_ORDERS",
    CHOOSE_ADMIN_ORDER = "CHOOSE_ADMIN_ORDER",
    LOAD_ORDER_FROM_START = "LOAD_ORDER_FROM_START",
    PUT_ORDER_FROM_START = "PUT_ORDER_FROM_START",
    DELETE_ORDER_FROM_START = "DELETE_ORDER_FROM_START",
    LOAD_ADMIN_ITEM_FROM_START = "LOAD_ADMIN_ITEM_FROM_START",
    PUT_ADMIN_ITEM_FROM_START = "PUT_ADMIN_ITEM_FROM_START",
    DELETE_ADMIN_ITEM_FROM_START = "DELETE_ADMIN_ITEM_FROM_START",
    DELETE_ORDER = "DELETE_ORDER"
}

interface LoadAdminItemFromStart {
    type: typeof AdminDataActions.LOAD_ADMIN_ITEM_FROM_START,
    payload: { id: string, lang: string }
}

interface PutAdminItemFromStart {
    type: typeof AdminDataActions.PUT_ADMIN_ITEM_FROM_START,
    payload: Card[]
}

interface DeleteAdminItemFromStart {
    type: typeof AdminDataActions.DELETE_ADMIN_ITEM_FROM_START
}

interface LoadOrderFromStart {
    type: typeof AdminDataActions.LOAD_ORDER_FROM_START,
    payload: { orderId: string, accessToken: string }
}

interface PutOrderFromStart {
    type: typeof AdminDataActions.PUT_ORDER_FROM_START,
    payload: any
}

interface DeleteOrderFromStart {
    type: typeof AdminDataActions.DELETE_ORDER_FROM_START
}

interface LoadAdminData {
    type: typeof AdminDataActions.LOAD_ADMIN_DATA,
    payload: { startRent: string, endRent: string, type: string[] }
}

interface PutAdminData {
    type: typeof AdminDataActions.PUT_ADMIN_DATA,
    payload: any
}

interface ChooseItem {
    type: typeof AdminDataActions.CHOOSE_ADMIN_ITEM,
    payload: Card
}

interface LoadAdminDataOrders {
    type: typeof AdminDataActions.LOAD_ADMIN_DATA_ORDERS,
    payload: { startRent: string, endRent: string }
}

interface PutAdminDataOrders {
    type: typeof AdminDataActions.PUT_ADMIN_DATA_ORDERS,
    payload: any
}

interface ChooseOrder {
    type: typeof AdminDataActions.CHOOSE_ADMIN_ORDER,
    payload: Card
}

interface DeleteOrder {
    type: typeof AdminDataActions.DELETE_ORDER
}

export type AdminDataType =
    LoadAdminData
    | PutAdminData
    | ChooseItem
    | LoadAdminDataOrders
    | PutAdminDataOrders
    | ChooseOrder
    | LoadOrderFromStart
    | PutOrderFromStart
    | DeleteOrderFromStart
    | LoadAdminItemFromStart
    | PutAdminItemFromStart
    | DeleteAdminItemFromStart
    | DeleteOrder;
