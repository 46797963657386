import {FilterActions, FiltersType} from '../actions/filterActions';
import {Filter} from "../constants";

const initialState = {
    types: [],
    asideFilters: {
        categories: [],
        heights: [],
        genders: []
    },
    asideFiltersComp: ['Сбросить'],
    filteredByType: [],
    sortOrder: 'price,asc'
}

export const filterReducer = (state: Filter = initialState, action: FiltersType): Filter => {
    switch (action.type) {
        case FilterActions.SET_SORT_ORDER:
            return {
                ...state,
                sortOrder: action.payload
            }
        case FilterActions.ADD_TYPE:
            sessionStorage.setItem('type', action.payload);
            return {
                ...state,
                types: [action.payload]
            }
        case FilterActions.DELETE_TYPE:
            return {
                ...state,
                types: state.types.filter((f: string) => f !== action.payload)
            }
        case FilterActions.ASIDE_ADD_CATEGORIES_FILTER:
            return {
                ...state,
                asideFilters: {
                    ...state.asideFilters,
                    categories: [action.payload]
                }
            }
        case FilterActions.ASIDE_DELETE_CATEGORIES_FILTER:
            return {
                ...state,
                asideFilters: {
                    ...state.asideFilters,
                    categories: state.asideFilters.categories.filter((f: string) => f !== action.payload)
                }
            }
        case FilterActions.ASIDE_ADD_HEIGHTS_FILTER:
            return {
                ...state,
                asideFilters: {
                    ...state.asideFilters,
                    heights: action.payload
                }
            }
        case FilterActions.ASIDE_DELETE_HEIGHTS_FILTER:
            return {
                ...state,
                asideFilters: {
                    ...state.asideFilters,
                    heights: state.asideFilters.heights.filter((f: string) => f !== action.payload)
                }
            }
        case FilterActions.ASIDE_ADD_GENDERS_FILTER:
            return {
                ...state,
                asideFilters: {
                    ...state.asideFilters,
                    genders: [action.payload]
                }
            }
        case FilterActions.ASIDE_DELETE_GENDERS_FILTER:
            return {
                ...state,
                asideFilters: {
                    ...state.asideFilters,
                    genders: state.asideFilters.genders.filter((f: string) => f !== action.payload)
                }
            }
        case FilterActions.ASIDE_ADD_FILTER_COMP:
            return {
                ...state,
                asideFiltersComp: [...state.asideFiltersComp, action.payload]
            }
        case FilterActions.ASIDE_ADD_FILTER_HEIGHTS_COMP:
            return {
                ...state,
                asideFiltersComp: action.payload
            }
        case FilterActions.ASIDE_DELETE_FILTER_COMP:
            return {
                ...state,
                asideFiltersComp: state.asideFiltersComp.filter((f: string) => !f.includes(action.payload))
            }
        case FilterActions.DROP_ASIDE_FILTER_COMP:
            return {
                ...state,
                asideFilters: {
                    categories: [],
                    heights: [],
                    genders: []
                },
                asideFiltersComp: ['Сбросить']
            }
        default:
            return state
    }
}
