export enum RouteActions {
    LOAD_ROUTE = "LOAD_ROUTE",
    PUT_ROUTE = "PUT_ROUTE",
}

interface LoadRoute{
    type: typeof RouteActions.LOAD_ROUTE,
    payload: {}[]
}

interface PutRoute{
    type: typeof RouteActions.PUT_ROUTE,
    payload: {}[]
}

export type RouteType = LoadRoute | PutRoute;