import React from 'react';

import Toolsboard from "../Toolsboard";

import './style.scss';

interface IReservation {
    title: string,
    tip: string,
    toolsBoardLang: any
}

const Reservation: React.FC<IReservation> = ({title, tip, toolsBoardLang}) => {
    return (
        <div className='res' style={{background: "url('/images/bg.jpg') no-repeat", backgroundSize: 'cover'}}>
            <div className="res_inner containerMain">
                <h2 className="res_title">{title}</h2>
                <p className="res_text">{tip}</p>
                <Toolsboard toolsBoardLang={toolsBoardLang}/>
            </div>
        </div>
    );
};

export default Reservation;
